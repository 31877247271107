import BookingItem from "@/classes/bookings/BookingItem";
import Payment from "@/classes/bookings/Payment";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {ReHelper} from "@/classes/common/ReHelper";
import BookingMultiTableDiscount from "@/classes/bookings/BookingMultiTableDiscount";
import {sumBy} from "lodash-es";
import Show from "@/classes/floor-map/Show";

type ShowBriefInfo = { name: string; location: string; start_date: string, end_date: string };

export default class Booking {
    id: number;
    vendor_id: number;
    show_id: number | null;
    floorplan_id: number;
    total: number;
    custom_discount: number;
    // discount_total: number;
    amount_paid: number;
    status: number;
    notes_user: string;
    notes_admin: string;
    placed_by: string;
    created_on: string;

    booth_cnt?: number;

    show: Show | null;
    items: BookingItem[];
    payments: Payment[];
    payments_total?: number;
    booths_total?: number;
    services_total?: number;
    balance_due?: number;
    discount_total?: number;
    multi_table_discounts?: BookingMultiTableDiscount[];
    shared_vendors?: { vendor_id: number, vendor_name: string }[];

    vendor_name?: string;
    vendor_email?: string;

    booths?: string;
    // vendor?: { id: number, name: string };

    /*    get balance_due() {
            return (this.total ?? 0) - (this.payments_total ?? 0);
        }*/

    get booth_items(): BookingItem[] {
        return this.items.filter(i => i.item_type == 1);
    }

    get service_items(): BookingItem[] {
        return this.items.filter(i => i.item_type == 2);
    }

    get min_pay_amount() {
        //limit the first payment only
        return this.payments?.length ? 0 : Math.min(this.balance_due ?? this.total, this.total * BookingsRepo.min_payment_amount_percentage / 100);
    }

    get items_total(): number {
        return (this.booths_total ?? 0) + (this.services_total ?? 0);
    }

    get multi_table_discounts_total(): number {
        return sumBy(this.multi_table_discounts ?? [], d => d.discount_total);
    }

    constructor(id: number, vendor_id: number, show_id: number | null, floorplan_id: number, items: BookingItem[], total: number, custom_discount: number,
                amount_paid: number, status: number, notes_user: string, notes_admin: string, placed_by: string, created_on: string, payments: Payment[], booth_cnt?: number,
                vendor_name?: string, vendor_email?: string, payments_total?: number, booths_total?: number, services_total?: number, balance_due?: number,
                discount_total?: number, multi_table_discounts?: BookingMultiTableDiscount[], booths?: string, shared_vendors?: { vendor_id: number, vendor_name: string }[],
                show: Show | null = null) {
        this.id = id;
        this.vendor_id = vendor_id;
        this.show_id = show_id;
        this.floorplan_id = floorplan_id;
        this.items = items;
        this.total = total;
        this.custom_discount = custom_discount;
        this.amount_paid = amount_paid;
        this.status = status;
        this.notes_user = notes_user;
        this.notes_admin = notes_admin;
        this.placed_by = placed_by;
        this.created_on = created_on;
        this.payments = payments;

        this.show = show;
        this.booth_cnt = booth_cnt;
        this.vendor_name = vendor_name;
        this.vendor_email = vendor_email;
        this.payments_total = payments_total;
        this.booths_total = booths_total;
        this.services_total = services_total;
        this.balance_due = balance_due;
        this.discount_total = discount_total;
        this.multi_table_discounts = multi_table_discounts;
        this.booths = booths;
        this.shared_vendors = shared_vendors ?? [];
    }

    static from(o: any) {
        return new Booking(o.id,
            o.vendor_id,
            o.show_id,
            o.floorplan_id,
            o.items?.map((i: any) => BookingItem.from(i)) ?? [],
            o.total,
            o.custom_discount,
            o.amount_paid,
            o.status,
            o.notes_user,
            o.notes_admin,
            o.placed_by,
            o.created_on,
            o.payments?.map((p: any) => Payment.from(p)),
            o.booth_cnt,
            o.vendor_name,
            o.vendor_email,
            o.payments_total,
            o.booths_total,
            o.services_total,
            o.balance_due,
            o.discount_total,
            o.multi_table_discounts?.map((d: any) => BookingMultiTableDiscount.from(d)),
            o.booths,
            o.shared_vendors,
            o.show ? Show.from(o.show) : null,
        );
    }

    static default(): Booking {
        return new Booking(0, 0, null, 0, [], 0, 0, 0, 0, "", "", "", "", []);
    }

    clone() {
        return Booking.from(this);
    }

    get name() {
        return '(Booking)';
    }

    prepare_for_save() {
        this.created_on ||= ReHelper.now;
        if (this.show?.id) this.show_id = this.show.id;
        return {...this, ...{show: undefined}};
    }
}