
























































import {AuthRepo} from '@/classes/repos/AuthRepo';
import {Component, Prop, Vue} from 'vue-property-decorator';
import Logo from "@/components/common/Logo.vue";
import {BForm} from "bootstrap-vue";
import ValidationSummary from "@/components/common/ValidationSummary.vue";
import PasswordEditor from "@/components/common/PasswordEditor.vue";

@Component<ActivateAccount>(
    {
      components: {PasswordEditor, ValidationSummary, Logo}
    }
)
export default class ActivateAccount extends Vue {
  $refs!: {
    form: BForm;
    pwd_editor: PasswordEditor;
  }
  @Prop()
  token?: string;

  private loading = true;

  private get mode() {
    return this.$route.name === 'password_reset' ? 'password_reset' : 'activate_account';
  }

  private token_ok = false;

  private email = '';
  private first_name = '';
  private last_name = '';
  private pwd = '';

  private validation_errors: any = null;

  private submit_pressed = false;

  private form_valid(): boolean {
    return this.$refs.pwd_editor.form_valid();
  }

  private async mounted() {
    if (this.token) {
      const {token_ok, first_name, last_name, email} = await AuthRepo.check_token(this.mode, this.token);

      this.loading = false;

      if (token_ok) {
        this.token_ok = true;
        this.first_name = first_name;
        this.last_name = last_name;
        this.email = email;
      }
    }
  }

  private async submit() {
    this.submit_pressed = true;

    if (!this.form_valid() || !this.$refs.form.checkValidity()) {
      this.$refs.form.reportValidity();
      return;
    }

    await this.$recaptchaLoaded();
    const recaptcha_token = await this.$recaptcha(this.mode);

    const {success, errors} = await AuthRepo.activate_account(this.mode, this.token!, this.pwd, recaptcha_token);

    if (success) {
      await this.$router.push({
                                name  : 'confirmation',
                                params: {message: this.mode == 'activate_account' ? 'Congratulations! Your account is active now.' : 'Your password has been changed.'}
                              });
    } else {
      this.validation_errors = errors!;
    }


  }
}
