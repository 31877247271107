














import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import Booking from "@/classes/bookings/Booking";
import PayPalButtons from "@/components/Reservations/PayPalButtons.vue";
import {EventBus} from "@/classes/common/EventBus";
import {BModal} from "bootstrap-vue";

@Component<ButtonNewPayment>(
    {
      components: {PayPalButtons}
    }
)
export default class ButtonNewPayment extends Vue {
  @Prop()
  booking!: Booking;

  @Prop({default: 'New Payment'})
  button_title!: string;

  private get payments_pending() {
    return this.booking.balance_due! > 0;
  }

  @Ref('payment_modal')
  private payment_modal!: BModal;

  beforeMount() {
    EventBus.$on('paypal_payment_completed', this.paypal_payment_completed);
  }

  private async paypal_payment_completed() {
    if (this.payment_modal)
      this.payment_modal.hide();
    this.$emit('booking_changed');
  }
}
