









































import {Component, Vue, Watch} from 'vue-property-decorator';
import {RawLocation, Route} from "vue-router";
import Palette from "@/components/floor-map/Palette.vue";
import Config from "@/classes/common/Config";
import {ReHelper} from "@/classes/common/ReHelper";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ShoppingCart from "@/components/Reservations/ShoppingCart.vue";
import ShoppingCartFooter from "@/components/Reservations/ShoppingCartFooter.vue";
import {AppRepo} from "@/classes/repos/AppRepo";
import BookingItem from "@/classes/bookings/BookingItem";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {MapRepo} from "@/classes/repos/MapRepo";
import {EventBus} from "@/classes/common/EventBus";
import {JwtRepo} from '@/classes/repos/JwtRepo';
import App from "@/App.vue";

@Component<MapPagesGroup>(
    {
      components: {ShoppingCartFooter, ShoppingCart, Palette},
      async beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: MapPagesGroup) => void))) => void) {


        const {
                errors,
                show,
                success
              } = await ShowsGenericRepo.load_by_slug(to.params?.slug, to.params?.fp_slug, <string>to.query.selection);

        if (errors) {
          // console.log(errors)
          App.notify(errors.single_err_msg, 'danger');
          next('/');
        } else if (!show?.maps?.length && to.name !== 'floorplan') {
          if (AppRepo.ui_level === "admin")
            next({
              name: 'floorplan',
              params: {
                slug: to.params?.slug,
                fp_slug: to.params?.fp_slug
              }
            });
          else next(false);
        } else next();
      },
      async beforeRouteUpdate(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: any) => void))) => void) {
        await ShowsGenericRepo.load_by_slug(to.params?.slug, to.params?.fp_slug, <string>to.query.selection);
        next(async vm => {
        });
      }
    }
)
export default class MapPagesGroup extends Vue {
  private config = Config;

  private right_sidebar_opened = !ReHelper.narrow_screen && JwtRepo.is_logged_in;

  private MapRepo = MapRepo;
  private JwtRepo = JwtRepo;


  async mounted() {
    await this.update_bookings_for_map();
  }

  private async load_bookings_for_map() {
    const show_id = ShowsGenericRepo.current_show?.id;
    const floorplan_id = MapRepo.current_map?.id;

    if (!show_id || !floorplan_id) return;

    const data: { success: boolean; booking_items?: BookingItem[]; errors?: any } = await BookingsRepo.list_for_map(show_id, floorplan_id);
    if (!data.booking_items) return;

    EventBus.$emit('loaded_bookings_for_map', data);
  }

  @Watch('MapRepo.current_map')
  private async update_bookings_for_map() {
    if (this.$route.meta?.update_bookings_map)
      await this.load_bookings_for_map();
    setTimeout(async () => this.update_bookings_for_map(), 30 * 1000);
  }


}
