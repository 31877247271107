




import {Component, Prop, Watch} from 'vue-property-decorator';
import {fabric} from "fabric";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component(
    {}
)
export default class ReFabricRect extends ReFabricObject {
  fjs_object?: fabric.Rect;

  @Prop({default: false})
  listen_size_from_model!: boolean;

  @Prop()
  fill!: string;

  @Prop()
  is_highlighted!: boolean;

  @Prop()
  is_occupied!: boolean;

  get adjusted_fill() {
    if (this.is_highlighted) return MapRepo.booth_highlighted_fill_color;
    if (this.is_occupied) return MapRepo.booth_occupied_fill_color;
    if (!this.fill) return MapRepo.booth_fill_color;
    if (this.fill.includes('rgba')) return this.fill;
    return this.fill + '80';
  }

  private MapRepo = MapRepo;

  protected create_fjs_object(callback: () => void) {
    this.fjs_object = new fabric.Rect({
                                        // canvas     : MapRepo.re_canvas!.fjs_canvas,
                                        left       : this.loc.left,
                                        top        : this.loc.top,
                                        fill       : this.adjusted_fill,
                                        width      : this.loc.width,
                                        height     : this.loc.height,
                                        strokeWidth: this.bordered ? 1 : 0,
                                        stroke     : this.bordered ? MapRepo.booth_edited_stroke_color : MapRepo.booth_fill_color,
                                        hasControls: false,
                                        // angle      : this.angle ?? 0,

                                        // originX: 'left',
                                        // originY: 'top',

                                      });
    this.fjs_object?.set({angle: this.angle ?? 0});

    (window as any)['fjs_rect'] = this.fjs_object;
    // console.log(`${this.constructor.name}.create_fjs_object (${this.debug_name}):`, this.loc.toString(), this.angle);

    callback();
  }

  @Watch('loc.width')
  modified_width_from_model(w: number) {
    if (!this.listen_size_from_model) return;
    // console.log(`${this.constructor.name}.modified_width_from_model (${this.debug_name}):`, w);
    this.fjs_object?.set({width: w});
    this.fjs_object?.set({left: -w / 2});
    this.fjs_object?.setCoords();
  }

  @Watch('loc.height')
  modified_height_from_model(h: number) {
    if (!this.listen_size_from_model) return;
    // console.log(`${this.constructor.name}.modified_height_from_model (${this.debug_name}):`, h);
    this.fjs_object?.set({height: h});
    this.fjs_object?.set({top: -h / 2});
    this.fjs_object?.setCoords();
  }

  @Watch('is_occupied')
  @Watch('is_highlighted')
  @Watch('fill')
  @Watch('loc.width')
  @Watch('loc.height')
  private modified_is_highlighted(v: any) {
    // console.log(`${this.constructor.name}.modified_is_highlighted (${this.debug_name}):`, v);
    this.fjs_object?.set({fill: this.adjusted_fill});
    MapRepo.active_canvas?.render_all();
  }

}

