


























import App from '@/App.vue';
import {logo_url} from '@/classes/common/Const';
import {AuthRepo} from '@/classes/repos/AuthRepo';
import {Component, Prop, Vue} from 'vue-property-decorator';
import VendorProfileMenu from "@/components/common/VendorProfileMenu.vue";

@Component<AccountMenu>(
    {
      components: {VendorProfileMenu}
    }
)
export default class AccountMenu extends Vue {
  private App = App;
  private AuthRepo = AuthRepo;
  private logo_url = logo_url;

  @Prop()
  is_dropdown!: boolean;

  get component() {
    return this.is_dropdown ? 'b-dropdown-item' : 'b-link';
  }

  /*  get waitlist_enabled(): boolean {
      return !!ShowsGenericRepo.current_show?.waitlist_enabled;
    }*/
}
