


































































import {Component, Vue} from 'vue-property-decorator';
import FloorPlanEditor from "@/components/editors/FloorPlanEditor.vue";
import FloorMapLib from "@/classes/floor-map/FloorMapLib";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {RawLocation, Route} from "vue-router";
import FloorMap from "@/classes/floor-map/FloorMap";
import {MapLibrariesGenericRepo} from "@/classes/repos/MapLibrariesGenericRepo";

@Component<FloorplanSetupPage>(
    {
      components: {FloorPlanEditor},
      /*      beforeRouteUpdate(to, from, next) {
            },*/
      beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: FloorplanSetupPage) => void))) => void) {
        next(vm => {
          const fp_slug = to.params.fp_slug;
          let map: FloorMap | undefined;
          if (fp_slug) {
            map = ShowsGenericRepo.current_show?.maps?.find(m => m.floor_plan?.slug === fp_slug);
          }
          if (map) {
            vm.is_new = false;
            vm.editing_map = map.clone();
            vm.orig_map = map;
          } else {
            vm.is_new = true;
            vm.editing_map = FloorMap.default();
            vm.orig_map = null;
          }
        })
      }

    }
)
export default class FloorplanSetupPage extends Vue {
  private editing_map: FloorMap | null = null;
  private orig_map: FloorMap | null = null;
  private is_new: boolean = true;

  get floorMapLibs(): FloorMapLib[] {
    return MapLibrariesGenericRepo.floorMapLibs;
  }

  private n_preview_item?: number | null = null;

  get preview_title() {
    return this.floorMapLibs[this.n_preview_item ?? 0]?.map?.floor_plan?.name;
  }

  selected_map_lib?: FloorMapLib | null = null;

  private async beforeMount() {
    await MapLibrariesGenericRepo.load_map_library();
  }

  private toggle_map_lib_selection(ml: FloorMapLib) {
    if (ml == this.selected_map_lib) this.selected_map_lib = null;
    else this.selected_map_lib = ml;
  }

  private async delete_map_lib(ml: FloorMapLib) {
    const confirmed: boolean = await this.$bvModal.msgBoxConfirm(`Delete this library item?`);
    if (!confirmed) return;
    if (ml === this.selected_map_lib)
      this.selected_map_lib = this.n_preview_item = null;
    await MapLibrariesGenericRepo.remove(ml.id);
    await MapLibrariesGenericRepo.load_map_library();
  }

  private preview_map_lib(n: number) {
    this.n_preview_item = n;
    this.$bvModal.show('preview_modal')
  }
}
