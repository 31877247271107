





















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component<CollapsibleCard>(
    {}
)
export default class CollapsibleCard extends Vue {
  @Prop({default: ''})
  header!: string;

  @Prop({default: 'font-weight-bold'})
  headerClass!: string;

  @Prop({default: 'px-1 px-md-2'})
  bodyClass!: string;

  @Prop({default: true})
  default_opened!: boolean;

  private opened = true;

  private static n = 0;

  private id = `collapsible-card-collapse-${++CollapsibleCard.n}`;

  mounted() {
    this.opened = this.default_opened;
  }

}
