















































import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import {fabric} from "fabric";
import Loc from "@/classes/common/Loc";
import Booth, {MapObject} from "@/classes/floor-map/Booth";
import ICanvasParent from "@/components/interfaces/ICanvasParent";
import ReFabricImage from "@/components/re-fabric/re-fabric-image.vue";
import FloorPlan from "@/classes/floor-map/FloorPlan";
import BoothComp from "@/components/floor-map/BoothComp.vue";
import {pix_per_foot} from "@/classes/common/Const";
import Config from "@/classes/common/Config";
import {ActiveSelection, IEvent, Point} from "fabric/fabric-impl";
import {MapRepo} from "@/classes/repos/MapRepo";
import ComplexBoothComp from "@/components/floor-map/ComplexBoothComp.vue";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import {ReHelper} from "@/classes/common/ReHelper";
import MapObjectCompBase from "@/components/floor-map/MapObjectCompBase";
import PrintOnlyObjectComp from "@/components/floor-map/PrintOnlyObjectComp.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {AppRepo} from "@/classes/repos/AppRepo";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component(
    {
      components: {
        ReFabricImage,
        PrintOnlyObjectComp,
        ComplexBoothComp, BoothComp,
      },
    }
)
export default class ReFabricCanvas extends Vue implements ICanvasParent {
  $refs!: {
    wrap: HTMLDivElement;
    booth_comps: BoothComp[];
    complex_booth_comps: ComplexBoothComp[];
  }

  fjs_canvas?: fabric.Canvas;

  private canvasContainer?: HTMLDivElement;

  @Prop({default: false})
  visible!: boolean;

  private MapRepo = MapRepo;
  private AppRepo = AppRepo;

  @Ref()
  private floor_plan_image!: PrintOnlyObjectComp;


  get defaultCursor() {
    return this.drawing_booths || this.placing_object ? 'cell' : 'default';
  }

  get zoom(): number {
    return MapRepo.zoom;
  }

  private get map() {
    return MapRepo.current_map!;
  }

  private get map_objects() {
    return this.map.mapObjects;
  }

  private get map_objects_comps(): MapObjectCompBase[] {
    return [...<MapObjectCompBase[]>this.$refs.complex_booth_comps ?? [], ...this.$refs.booth_comps ?? []];
  }

  private get grid_on() {
    return AppRepo.ui_level === "admin" && this.map.grid_on;
  }

  @Prop()
  floor_plan?: FloorPlan;

  @Prop({default: 8 * pix_per_foot})
  grid_step_x!: number;

  @Prop({default: 8 * pix_per_foot})
  grid_step_y!: number;

  private config = Config;

  fjs_selection_rect: Loc | null = null;

  dragging_now = false;
  scaling_now = false;

  @Watch('zoom')
  zoom_changed(newVal: number) {
    this.fjs_canvas?.setZoom(newVal);
  }

  @Watch('grid_step_x')
  @Watch('grid_step_y')
  private grid_step_changed() {
    this.draw_grid();
  }

  render_all() {
    this.fjs_canvas?.requestRenderAll();
    this.fjs_canvas?.calcOffset();
  }

  child_added(o: fabric.Object) {
    // console.log(`${this.constructor.name} child_added`, arguments);
    this.fjs_canvas?.add(o);
  }

  c!: HTMLCanvasElement;

  canvas_w = 1400;
  canvas_h = 800;

  @Prop({default: false})
  drawing_booths!: boolean;

  @Prop({default: false})
  placing_object!: boolean;

  created() {
    MapRepo.active_canvas = this;
  }

  private beforeMount() {
    // console.log('canvas beforeMount');
    MapRepo.internal_zoom = Math.min(this.canvas_w / this.floor_plan!.loc.width, this.canvas_h / this.floor_plan!.loc.height);
    // console.log('MapRepo.internal_zoom', MapRepo.internal_zoom);

    this.c = document.createElement('canvas');
    this.fjs_canvas = new fabric.Canvas(this.c, {
      allowTouchScrolling: true,
      defaultCursor: this.defaultCursor,
      enableRetinaScaling: false,
      fireRightClick: true,
      height: this.canvas_h,
      selection: !MapRepo.panning_mode,
      stopContextMenu: true,
      width: this.canvas_w,
    });

    this.fjs_canvas.setZoom(this.zoom);

    this.fjs_canvas.on('selection:cleared', () => this.$emit('selection_cleared'));
    (this.fjs_canvas as any).on({
      'selection:created': this.selection_changed,
      'selection:updated': this.selection_changed,
      // 'selection:cleared': this.selection_changed,
    });
    this.fjs_canvas.on('object:moving', this.object_moving);
    this.fjs_canvas.on('object:scaling', this.object_scaling);
    /*this.fjs_canvas.on('object:added', function () {
      console.log('object:added', arguments);
    });*/
    this.fjs_canvas.on('object:modified', this.object_modified);
    this.fjs_canvas.on('mouse:up', this.mouse_up);
    this.fjs_canvas.on('mouse:down', this.mouse_down);
    this.fjs_canvas.on('mouse:move', this.mouse_move);
    this.listen_panning();
    (window as any)['fjs_canvas'] = this.fjs_canvas;
  }

  private mounted() {
    /*this.fjs_canvas = new fabric.Canvas(this.$refs.canvas, {
      width: 1000,
      height: 400
    });*/

    // console.log('canvas mounted');

    // this.$refs.wrap.innerHTML = '';
    this.canvasContainer = (this.fjs_canvas as any).wrapperEl;
    this.$refs.wrap.appendChild(this.canvasContainer!);
    this.canvasContainer!.addEventListener('drop', this.handleDrop, false);
    // console.log(`${this.constructor.name}.mounted:`, this.fjs_canvas!._objects);
    this.draw_grid();
    this.fit_canvas();
  }

  private listen_panning() {
    const canvas = this.fjs_canvas! as any;

    if (ReHelper.touch) {
      let pausePanning = false;
      let zoomStartScale = 1;
      let currentX = 0, currentY = 0, xChange = 0, yChange = 0, lastX = 0, lastY = 0;
      canvas.on({
        'touch:gesture': function (e: any) {
          // console.log('listen_panning: touch:gesture', e);
          if (e.e.touches && e.e.touches.length == 2) {
            pausePanning = true;
            const point = new fabric.Point(e.self.x, e.self.y);
            if (e.self.state == "start") {
              zoomStartScale = canvas.getZoom();
            }
            const delta = zoomStartScale * e.self.scale;
            if (MapRepo.is_zoom_acceptable(delta)) {
              canvas.zoomToPoint(point, delta);
              MapRepo.adjust_public_zoom(delta);
            }
            pausePanning = false;
          }
        },
        'object:selected': function () {
          pausePanning = true;
        },
        'selection:cleared': function () {
          pausePanning = false;
        },
        'touch:drag': function (e: any) {
          // console.log('listen_panning: touch:drag', e);
          if (MapRepo.panning_mode && !pausePanning && e.self.x != undefined && e.self.y != undefined) {
            currentX = e.self.x;
            currentY = e.self.y;
            xChange = currentX - lastX;
            yChange = currentY - lastY;

            if ((Math.abs(currentX - lastX) <= 50) && (Math.abs(currentY - lastY) <= 50)) {
              const delta = new fabric.Point(xChange, yChange);
              canvas.relativePan(delta);

              const vpt = canvas.viewportTransform;
              MapRepo.pan_x = vpt[4];
              MapRepo.pan_y = vpt[5];
            }

            lastX = e.self.x;
            lastY = e.self.y;
          }
        }
      });

    } else {
      canvas.on({
        'mouse:down': function (opt: any) {
          // console.log('listen_panning: mouse:down', opt);
          const e = opt.e;
          if (MapRepo.panning_mode) {
            canvas.isDragging = true;
            canvas.selection = false;

            const client_x = e.clientX || opt.pointer.x;
            const client_y = e.clientY || opt.pointer.y;

            canvas.lastPosX = client_x;
            canvas.lastPosY = client_y;
          }
        },
        'mouse:move': function (opt: any) {
          // console.log('listen_panning: mouse:move', opt);
          if (canvas.isDragging) {
            const e = opt.e;
            const vpt = canvas.viewportTransform;
            const client_x = e.clientX || opt.pointer.x;
            const client_y = e.clientY || opt.pointer.y;

            vpt[4] += client_x - canvas.lastPosX;
            vpt[5] += client_y - canvas.lastPosY;
            MapRepo.pan_x = vpt[4];
            MapRepo.pan_y = vpt[5];
            canvas.requestRenderAll();
            canvas.lastPosX = client_x;
            canvas.lastPosY = client_y;
          }
        },
        'mouse:up': function (opt: any) {
          // on mouse up we want to recalculate new interaction
          // for all objects, so we call setViewportTransform
          // console.log('listen_panning: mouse:up', opt);
          canvas.setViewportTransform(canvas.viewportTransform);
          canvas.isDragging = false;
          canvas.selection = true;
        }
      })
    }


  }

  @Watch('MapRepo.panning_mode')
  panning_mode_on_off(v: boolean) {
    (this.fjs_canvas as any).set({selection: !v});
  }


  private mouse_down(event: IEvent) {
    this.dragging_now = false;
  }

  private mouse_move(event: IEvent) {
    // this.dragging_now = true;
  }

  private mouse_up(event: IEvent) {
    if ((this.drawing_booths || this.placing_object) && !this.dragging_now && !this.fjs_canvas?.getActiveObject()) {
      // console.log(`${this.constructor.name} mouse_up: `)
      const pointer = this.fjs_canvas!.getPointer(event.e);
      let x = pointer.x;
      let y = pointer.y;
      const gx = this.grid_step_x, gy = this.grid_step_y;

      //snapping to grid
      const d = 2; // d == 4 => 1/8 gx is delta
      if (this.map.grid_on && Math.round(x / gx * d) % d == 0 && Math.round(y / gy * d) % d == 0) {
        x = Math.round(x / gx) * gx;
        y = Math.round(y / gy) * gy;
      }

      this.dragging_now = false;
      // console.log(`${this.constructor.name} listen_mouse_down`, pointer);
      this.$emit('canvas_click', new Loc(y, x, 0, 0));
    }
  }

  private selection_changed(e: IEvent) {
    // console.log(`${this.constructor.name} selection_changed: `, e);
    // e.target!.hasRotatingPoint = false;
    // e.target!.set({hasRotatingPoint: false})

    // const activeSelection = e.target as ActiveSelection;

    const activeSelection = this.fjs_canvas!.getActiveObject() as ActiveSelection;
    // console.log(`${this.constructor.name} activeSelection: `, activeSelection);

    if (MapRepo.do_lock_objects_movements)
      activeSelection.set({lockMovementX: true, lockMovementY: true});

    (window as any)['fjs_selection'] = activeSelection;
    if (activeSelection?.hasControls && MapRepo.multiple_selected) {
      activeSelection.hasControls = false;
      this.render_all();
    }

    this.fjs_selection_rect = new Loc(activeSelection.top, activeSelection.left, activeSelection.width!, activeSelection.height!);
    // this.$emit('selection_changed', e)
  }

  private handleDrop(ev: DragEvent) {
    ev.preventDefault();
    // debugger
    const letter_index = ev?.dataTransfer?.getData('booth_letter_index');
    // console.log(`handleDrop: letter_index: ${letter_index}`)
    if (!letter_index) return;

    const offset = this.c.getBoundingClientRect();
    const internal_x = parseFloat(ev?.dataTransfer?.getData('internal_x')!);
    const internal_y = parseFloat(ev?.dataTransfer?.getData('internal_y')!);

    let x = (ev.pageX - offset.left) - internal_x;
    let y = (ev.pageY - offset.top) - internal_y;

    const mCanvas = this.fjs_canvas?.viewportTransform;
    const mInverse = fabric.util.invertTransform(mCanvas as any);
    let pointInObjectPixels = fabric.util.transformPoint(new fabric.Point(x, y), mInverse!);

    x = pointInObjectPixels.x;
    y = pointInObjectPixels.y;

    // const n_booth = ev?.dataTransfer?.getData('n_booth');

    // console.log(`${this.constructor.name} handleDrop`, ev, letter_index, x, y, internal_x, internal_y);
    const booth_type = BoothTypesGenericRepo.all_booth_types_by_letter[letter_index];
    const loc = Loc.from_point_and_rw_size(y, x, booth_type);
    const booth = Booth.from_loc_and_type(loc, booth_type);
    ShowsGenericRepo.current_show!.assign_numeric_index(booth);
    this.map_objects!.push(booth);
    this.$forceUpdate();
    // this.$nextTick(()=>this.render_all());
    // this.render_all();
    setTimeout(() => this.render_all(), 50);
  }

  private fjs_grid_lines: fabric.Object[] = [];

  get virt_w(): number {
    return this.fjs_canvas?.width! / this.zoom;
  }

  get virt_h(): number {
    return this.fjs_canvas?.height! / this.zoom;
  }

  draw_grid() {
    const c = this.fjs_canvas!;
    const w = this.map.floor_plan!.loc.width;
    const h = this.map.floor_plan!.loc.height;

    const opts = {
      hoverCursor: 'default',
      type: 'line',
      stroke: '#ccc',
      strokeWidth: 1 / this.zoom,
      selectable: false,
      visible: this.grid_on,
    };

    if (this.fjs_grid_lines.length) {
      this.fjs_canvas!.remove(...this.fjs_grid_lines);
      this.fjs_grid_lines = [];
    }

    for (let left = 0; left <= w; left += this.grid_step_x) {
      const line = new fabric.Line([left, 0, left, h], opts);
      this.fjs_grid_lines.push(line);
      c.add(line);
      line.sendToBack();
    }

    for (let top = 0; top <= h; top += this.grid_step_y) {
      const line1 = new fabric.Line([0, top, w, top], opts);
      this.fjs_grid_lines.push(line1);
      c.add(line1);
    }
  }


  @Watch("map.grid_on")
  @Watch("AppRepo.ui_level")
  public toggle_grid(grid_on?: boolean) {
    grid_on ??= this.grid_on;
    this.fjs_grid_lines.forEach(l => l.set({visible: grid_on}));
    this.fjs_canvas?.requestRenderAll();
  }

  private object_modified(options: IEvent) {
    this.dragging_now = false;
    this.scaling_now = false;
    this.fjs_selection_rect = Loc.from(options.target);
    if (MapRepo.selected_wrap_objects.length < 2) return;
    // console.log('object_modified', arguments);
    MapRepo.selected_wrap_objects.forEach(w => w.fjs_wrap_object.modified_from_fjs(undefined, this.fjs_selection_rect!));
  }

  private object_scaling(options: IEvent) {
    this.scaling_now = true;

  }

  magnetic_corners = {t: 0, l: 0, r: 90, b: 180, tr: 90, tl: 0, br: 180, bl: 270};

  private object_moving(options: IEvent) {
    this.dragging_now = true;

    const o = options.target!;

    if (!this.grid_on || o.angle! % 90 !== 0) return;

    const gx = this.grid_step_x, gy = this.grid_step_y;
    let origins = {x: '', y: ''};
    switch ((o.angle! - this.magnetic_corners[MapRepo.magnetic_side] + 720) % 360) {
      case 0:
        origins = {x: 'left', y: 'top'};
        break;
      case 90:
      case -270:
        origins = {x: 'left', y: 'bottom'};
        break;
      case 180:
      case -180:
        origins = {x: 'right', y: 'bottom'};
        break;
      case 270:
      case -90:
        origins = {x: 'right', y: 'top'};
        break;
    }
    let {x, y} = o.getPointByOrigin(origins.x, origins.y);
    // console.log(x, y, o.angle)
    /*if (Math.round(x / gx * 4) % 4 == 0 && Math.round(y / gy * 4) % 4 == 0) {
      o.setPositionByOrigin(<Point>{x: Math.round(x / gx) * gx, y: Math.round(y / gy) * gy}, origins.x, origins.y);
      o.setCoords();
    }*/

    if ((MapRepo.magnetic_side.includes('l') || MapRepo.magnetic_side.includes('r')) && Math.round(x / gx * 4) % 4 == 0) {
      x = Math.round(x / gx) * gx;
      o.setPositionByOrigin(<Point>{x, y}, origins.x, origins.y);
      o.setCoords();
    }
    if ((MapRepo.magnetic_side.includes('t') || MapRepo.magnetic_side.includes('b')) && Math.round(y / gy * 4) % 4 == 0) {
      y = Math.round(y / gy) * gy;
      o.setPositionByOrigin(<Point>{x, y}, origins.x, origins.y);
      o.setCoords();
    }
  }

  addChild(ch: ReFabricObject): void {
    this.fjs_canvas?.add(ch.fjs_object!);
  }

  removeChild(ch: ReFabricObject): void {
    this.fjs_canvas?.remove(ch.fjs_object!);
  }

  /*  selected_from_model(o: fabric.Object) {
      this.fjs_canvas?.setActiveObject(o);
    }*/

  set_active_object(o: fabric.Object) {
    this.fjs_canvas?.setActiveObject(o);
  }

  is_group: boolean = false;

  get_png(loc?: Loc, hide_bg = false, multiplier = 1): string {
    let viewport = this.fjs_canvas!.viewportTransform;
    this.fjs_canvas!.viewportTransform = [1, 0, 0, 1, 0, 0];

    if (hide_bg) this.floor_plan_image.$refs.image_wrap?.fjs_object?.set({visible: false});

    // this.render_all();

    const dataURL = this.fjs_canvas!.toDataURL({
      format: 'png',
      top: loc?.top,
      left: loc?.left,
      width: loc?.width,
      height: loc?.height,
      multiplier: multiplier
    });
    this.fjs_canvas!.viewportTransform = viewport;
    if (hide_bg) this.floor_plan_image.$refs.image_wrap.fjs_object?.set({visible: true});
    return dataURL;
  }

  @Watch('defaultCursor')
  private defaultCursor_changed(v: string) {
    (this.fjs_canvas as any)?.set({defaultCursor: v});
    this.fjs_canvas?.requestRenderAll();
  }

  private async duplicate_map_object(map_object: MapObject) {
    const clone = map_object.clone();
    clone.loc.top! += 50;
    clone.loc.left! += 50;
    clone.locked = false;
    MapRepo.current_map!.mapObjects.push(clone);
    if (clone.booth_or_complex_booth)
      ShowsGenericRepo.current_show?.assign_numeric_index(clone.booth_or_complex_booth);

    /*await this.$nextTick();
    const comp = this.map_objects_comps.find(c => c.map_object.UID === clone.UID);
    if (comp) {
      MapRepo.selected_wrap_objects = [comp];
      this.set_active_object(comp.fjs_wrap_object.fjs_object!);
      // this.render_all();
    }*/
  }

  private is_occupied_booth(booth: Booth) {
    if (!booth) return false;
    const vendor = BookingsRepo.occupancy[booth.booth_number]?.[0];
    return !!vendor && (vendor.id !== JwtRepo.vendor_id);
  }

  private is_highlighted_booth(booth: Booth) {
    return !!MapRepo.highlighted_booths[booth?.booth_number];
  }

  select_map_objects(map_objects: MapObject[]) {
    this.fjs_canvas?.discardActiveObject();

    const fjs_groups = this.map_objects_comps.filter(c => map_objects.some(o => c.map_object === o)).map(c => c.fjs_wrap_object.fjs_object!);

    const selection = new fabric.ActiveSelection(fjs_groups, {canvas: this.fjs_canvas});

    this.fjs_canvas?.setActiveObject(selection)

    this.render_all();
  }

  deselect_all() {
    this.fjs_canvas?.discardActiveObject();
    this.render_all();
  }

  /*  select_fp_image() {
      let canvas = this;
      if (!canvas.floor_plan?.imageURL) return;

      canvas.fjs_canvas!.discardActiveObject();
      const selection = new fabric.ActiveSelection([canvas.floor_plan_image.$refs.image_wrap.fjs_object!], {canvas: canvas.fjs_canvas});
      this.fjs_canvas?.setActiveObject(selection)
      this.render_all();
    }*/

  fit_canvas() {
    const canvas = this.fjs_canvas;
    if (!canvas) return;

    this.canvasContainer = (this.fjs_canvas as any).wrapperEl;

    const bounds = this.canvasContainer!.getBoundingClientRect();
    canvas.setWidth(bounds.width);
    canvas.setHeight(bounds.height);

    const map_w = this.map!.floor_plan!.loc.width!;
    const map_h = this.map!.floor_plan!.loc.height!;

    const canvas_w = canvas.width!;
    const canvas_h = canvas.height!;
    const h_ratio = canvas_h / map_h;
    const w_ratio = canvas_w / map_w;

    const zoom = Math.min(h_ratio, w_ratio);
    MapRepo.adjust_public_zoom(zoom);
    // canvas.zoomToPoint(new fabric.Point(canvas_w / 2, canvas_h / 2), zoom);
    canvas.absolutePan(new fabric.Point(0, 0));
    canvas.renderAll();
  }

}
