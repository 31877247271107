

















import {Component, Ref, Vue, Watch} from 'vue-property-decorator';
import BootstrapVue, {BootstrapVueIcons} from "bootstrap-vue";

import FloorMap from "@/classes/floor-map/FloorMap";
import MapComp from "@/components/floor-map/MapComp.vue";
import ReMenuSidebar from "@/components/common/re-menu-sidebar.vue";
import {EventBus} from "@/classes/common/EventBus";
import {MapRepo} from "@/classes/repos/MapRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import MapControls from "@/components/floor-map/MapControls.vue";

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

@Component({
             components: {
               MapControls,
               ReMenuSidebar,
               MapComp,
             },
           })
export default class MapEditor extends Vue {
  @Ref()
  private map_comp!: MapComp;

  private get show() {
    return ShowsGenericRepo.current_show;
  }

  private get map() {
    return MapRepo.current_map!;
  }

  mounted() {
    let self = this;
    EventBus.$on('reset_map', () => {
      self.map.mapObjects = [];
      self.map_comp?.remountCanvas();
    });
  }

  refresh() {
    this.$forceUpdate();
    this.map_comp.$refs.canvas?.render_all();
  }

  @Watch('map')
  private map_changed(m: FloorMap) {
    this.map_comp?.remountCanvas();
  }
}
