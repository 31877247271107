import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import axios from "axios";
import WaitlistItem from "@/classes/floor-map/WaitlistItem";

class _WaitlistRepo extends LikeableEntityGenericRepo<{ show_id?: number, vendor_id?: number, limit_by_ids?: number[], exclude_ids?: number[] }> {
    constructor() {
        super(WaitlistItem, 'Waitlist');
    }

    async toggle_waitlist_enabled(show_id: number, waitlist_enabled: boolean) {
        await axios.post('/show/toggle_waitlist_enabled', {}, {params: {show_id, waitlist_enabled}});
    }

}

export const WaitlistRepo = Vue.observable(new _WaitlistRepo());