


















import {Component, Vue} from 'vue-property-decorator';
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import VendorProfileMenu from "@/components/common/VendorProfileMenu.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<CheckoutMenu>(
    {
      components: {VendorProfileMenu}
    }
)
export default class CheckoutMenu extends Vue {
  private get company_has_alt_contact() {
    return !!CompaniesRepo.current_company?.contacts?.some(x => x.type === 'Alt Contact');
  }

  JwtRepo = JwtRepo;
}
