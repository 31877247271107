




























































import {Component, Prop, Vue} from 'vue-property-decorator';

import VueFileAgent from 'vue-file-agent';
import FloorMap from "@/classes/floor-map/FloorMap";
import ReImageUploader from "@/components/common/re-image-uploader.vue";
import slugify from "slugify";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import App from "@/App.vue";

Vue.use(VueFileAgent);

@Component<FloorPlanEditor>({
                              components: {
                                ReImageUploader,
                              },
                            })
export default class FloorPlanEditor extends Vue {
  @Prop({default: true})
  is_new!: boolean;

  @Prop({default: true})
  show_uploader!: boolean;

  @Prop()
  editing_map!: FloorMap;

  @Prop({default: null})
  orig_map!: FloorMap | null;

  private get floor_plan() {
    return this.editing_map.floor_plan;
  }

  private get fp_name_unique(): boolean | null {
    if (!this.floor_plan!.name) return null;
    return !ShowsGenericRepo.current_show?.maps?.some(m => m.floor_plan?.name == this.floor_plan!.name && m.id !== this.editing_map.id);
  }

  private form_valid(): boolean {
    return !!this.fp_name_unique;
  }

  private save_floorplan() {
    if (!this.form_valid()) return;
    this.floor_plan!.slug = slugify(this.floor_plan!.name, {lower: true});
    if (this.is_new) {
      ShowsGenericRepo.add_map(this.editing_map, true);
      App.notify('Floor plan added')
    } else {
      this.floor_plan!.copyTo(this.orig_map!.floor_plan!);
      App.notify('Floor plan saved')
    }

    this.$router.push({
                        name  : 'layout',
                        params: {slug: ShowsGenericRepo.current_show!.slug, fp_slug: this.floor_plan!.slug}
                      });

    // this.close();
  }

  private cancel() {
    (this.orig_map ?? FloorMap.default()).floor_plan!.copyTo(this.editing_map.floor_plan!);
    this.$emit('cancel');
  }

}
