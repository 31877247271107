









































import {Component, Vue} from 'vue-property-decorator';
import ReFilter from "@/components/common/ReFilter.vue";
import CompaniesTable from "@/components/generic/CompaniesTable.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import Show from "@/classes/floor-map/Show";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<Vendors>(
    {
      components: {ShowSelector, CompaniesTable, ReFilter}
    }
)
export default class Vendors extends Vue {
  private filter: string | null = null;

  private shows: Show[] = [];

  private filter_request: { booked_shows_ids: number[] } = {booked_shows_ids: []};

  created() {
    if (ShowsGenericRepo.current_show) {
      this.shows = [ShowsGenericRepo.current_show];
      this.shows_selection_changed();
    }
  }

  shows_selection_changed() {
    this.filter_request.booked_shows_ids = this.shows.map(sh => sh.id);
  }

  async add() {
    const {new_id} = await CompaniesRepo.create_empty();
    await this.$router.push({name: 'admin_vendor_profile', params: {vendor_id: String(new_id)}});
  }
}
