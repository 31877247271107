

















import {Component, Vue} from 'vue-property-decorator';
import Booth from "@/classes/floor-map/Booth";
import {MapRepo} from "@/classes/repos/MapRepo";
import {sumBy} from "lodash-es";

@Component<PricingQuickInfo>(
    {}
)
export default class PricingQuickInfo extends Vue {
  private get map() {
    return MapRepo.current_map!;
  }

  private get tables_count(): number {
    return sumBy(this.map.all_booths(), b => b.booth_type.table_cnt || 1);
  }

  private async reset_booth_pricing() {
    const confirmed: boolean = await this.$bvModal.msgBoxConfirm(`Reset the pricing for all booths?`);
    if (!confirmed) return;
    this.map.mapObjects.filter((o: any): o is Booth => !!o).forEach((b: Booth) => b.reset_price());
  }
}
