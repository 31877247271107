








































import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import Logo from "@/components/common/Logo.vue";
import Company from "@/classes/companies/Company";
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {BForm} from "bootstrap-vue";

@Component<Register>(
    {
      components: {Logo}
    }
)
export default class Register extends Vue {
  company!: Company;

  private user_account: { first_name?: string, last_name?: string, email?: string } = {};
  private user_account_created = false;
  private create_account_errors = '';

  @Ref() create_account_form!: BForm;

  @Prop({default: true})
  show_header!: boolean;

  async beforeMount() {
    /*this.company = Company.default();
    this.company.contacts = [
      CompanyContact.from({type: 'Owner', address_json: '{}', social_json: '{}'}),
      CompanyContact.from({type: 'Alt Contact', address_json: '{}', social_json: '{}'}),
    ]*/
  }

  private async create_account() {
    this.create_account_errors = '';
    if (!this.create_account_form.checkValidity()) {
      this.create_account_form.reportValidity();
      return;
    }

    await this.$recaptchaLoaded();
    const recaptcha_token = await this.$recaptcha('register_user');

    const {success, errors}
              = await AuthRepo.register_user(this.user_account.first_name!, this.user_account.last_name!, this.user_account.email!, recaptcha_token);

    this.user_account_created = success;
    this.create_account_errors = errors?.single_err_msg ?? '';

    this.$emit('done');
  }
}
