
import {Component, Prop} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {LikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import {RawLocation} from "vue-router";
import {WaitlistRepo} from "@/classes/repos/WaitlistRepo";
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import CompanyLink from "@/components/vendor/CompanyLink.vue";
import WaitlistItemEditor from "@/components/editors/WaitlistItemEditor.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import Show from "@/classes/floor-map/Show";
import {ReHelper} from "@/classes/common/ReHelper";

@Component<WaitlistItemsTable>(
    {components: {CompanyLink, WaitlistItemEditor}}
)
export default class WaitlistItemsTable extends EntityTable {
  repo = WaitlistRepo;
  TEntity = WaitlistItem;

  protected sort_by = 'created';
  protected sort_desc = false;
  editor_comp = 'WaitlistItemEditor';

  protected get default_fields(): (field_definition | undefined)[] {
    return [
      JwtRepo.is_admin ? {key: 'vendor_id', label: 'Exhibitor', sortable: false, component: 'CompanyLink'} : undefined,
      {key: 'show_name', label: 'Show', sortable: true,},
      {key: 'location', label: 'Location', sortable: true,},
      {
        key      : 'start_date',
        label    : 'Date',
        sortable : true,
        formatter: (value: string, key: string, item: WaitlistItem) => ReHelper.dates(item.start_date, item.end_date)
      },
      {key: 'request', sortable: false,},
      {key: 'created', label: 'Request Added', sortable: true, formatter: 'format_datetime'},

      JwtRepo.is_admin ? {key: 'edit', label: '', sortable: false,} : undefined,
      {key: 'delete', label: '', sortable: false,},
    ];
  }

  protected get entity_single_name(): string {
    return 'Waitlist Request';
  }

  protected setup_new_item(): LikeableEntity {
    let item = WaitlistItem.default();
    // item.show_id = ShowsGenericRepo.current_show!.id;
    if (this.$route.name === 'account_waitlist')
      item.vendor_id = JwtRepo.vendor_id;
    return item;
  }

  protected get show_add_btn() {
    return false;
  }

  @Prop({default: {}})
  filter_request: any;

  get search_request(): any {
    return this.filter_request;
  }

  actions: { key: string; text: string; to: (b: LikeableEntity) => RawLocation }[] = [
    {
      key : 'edit_company',
      text: 'Edit',
      to  : c => ({
        name  : 'admin_vendor_profile',
        params: {vendor_id: c.id.toString()}
      })
    }
  ]
}
