
import {Component} from 'vue-property-decorator';
import EntitySelector from "@/components/generic/EntitySelector.vue";
import {search_field_description} from "@/components/generic/EntitySearchForm.vue";
import {UsersRepo} from "@/classes/repos/UsersRepo";
import User from "@/classes/users/User";

@Component<UserSelector>(
    {}
)
export default class UserSelector extends EntitySelector {
  repo = UsersRepo;

  protected entity_plural_name = 'users';
  sort_by = 'first_name';
  // selected_items!: User[];

  get search_request_additional_params(): any {
    return {with_no_vendor_mappings: true}
  }

  protected search_fields: (string | search_field_description)[] = ['name', {name: 'email', row_start: false}];

  // protected search_results_fields = ['name', 'location', 'start_date'];

  protected entity_short_description(user: User): string {
    return `${user.short_description} (${user.email})`;
  }

}
