import {Exclude, instanceToPlain} from "class-transformer";
import {ReHelper} from "@/classes/common/ReHelper";

export default class WaitlistItem {
    id: number;
    show_id: number;
    vendor_id: number | null;
    show_name: string;
    location: string;
    start_date: string;
    end_date: string;
    request: string;
    @Exclude()
    created: string;

    @Exclude()
    vendor_name: string | null;

    constructor(id: number, show_id: number, vendor_id: number | null, show_name: string, location: string, start_date: string, end_date: string, request: string, created: string, vendor_name: string | null = null) {
        this.id = id;
        this.show_id = show_id;
        this.vendor_id = vendor_id;
        this.show_name = show_name;
        this.location = location;
        this.start_date = start_date;
        this.end_date = end_date;
        this.request = request;
        this.created = created;

        this.vendor_name = vendor_name;
    }

    static from(o: any): WaitlistItem {
        return new WaitlistItem(
            o.id,
            o.show_id,
            o.vendor_id,
            o.show_name,
            o.location,
            o.start_date,
            o.end_date,
            o.request,
            o.created,
            o.vendor_name,
        )
    }

    copyTo(w: WaitlistItem) {
        w.show_id = this.show_id;
        w.vendor_id = this.vendor_id;
        w.show_name = this.show_name;
        w.location = this.location;
        w.start_date = this.start_date;
        w.end_date = this.end_date;
        w.request = this.request;
        w.created = this.created;
        w.vendor_name = this.vendor_name;
    }

    prepare_for_save() {
        return instanceToPlain(this);
    }

    clone(): WaitlistItem {
        return WaitlistItem.from(this);
    }

    static default(): WaitlistItem {
        return new WaitlistItem(0, 0, null, "", "", "", "", "", ReHelper.now);
    }

    get name() {
        return this.request;
    }

    get short_description() {
        return this.request;
    }
}
