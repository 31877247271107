import {LikeableEntity, LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import Booking from "@/classes/bookings/Booking";
import axios from "axios";
import {ReHelper} from "@/classes/common/ReHelper";
import BookingItem from "@/classes/bookings/BookingItem";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {MapRepo} from "@/classes/repos/MapRepo";
import {groupBy, mapValues, uniqBy} from "lodash-es";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import {loadScript, PayPalNamespace} from "@paypal/paypal-js";

type Vendor = { id: number, name?: string, description?: string, logo?: string };

class _BookingsRepo extends LikeableEntityGenericRepo<{ show_id?: number, vendor_id?: number, start_date?: string, end_date?: string, limit_by_ids?: number[], exclude_ids?: number[] }> {
    protected entities_cache: Booking[] = [];
    paypal: PayPalNamespace | null = null;

    constructor() {
        super(Booking, "Booking");
        // EventBus.$on('current_show_changed', this.reset);
    }

    booth_in_cart_expires_min: number = 0;
    paypal_client_id = '';
    min_payment_amount_percentage: number = 10;
    multi_table_discount_min_booth_count: number = 4;

    booking_statuses: string[] = [];
    booking_item_types: string[] = [];

    booking_items: BookingItem[] = [];
    vendors: Vendor[] = [];
    categories: { id: number, name: string }[] = [];
    occupancy: { [booth_number: string]: Vendor[] } = {};

    current_booking: Booking | null = null;

    async load_paypal_buttons_script() {
        this.paypal = await loadScript({"client-id": BookingsRepo.paypal_client_id})!;
    }

    shopping_cart_not_empty() {
        return (this.current_booking?.items.length ?? 0) > 0;
    }

    get current_booking_items_grouped(): { [show_name: string]: { [floorplan_name: string]: BookingItem[] } } | undefined {
        if (!this.current_booking?.items) return undefined;
        return mapValues(groupBy(this.current_booking.items, i => `${i.show_name} (${ReHelper.dates(i.show_start_date!, i.show_end_date!)})`), (a: BookingItem[]) => groupBy(a, 'floorplan_name'));
    }

    private booked_booths_on_map_ts: number = 0;
    private booked_booths_on_map_count: number = 0;

    private shopping_cart_items_ts: number = 0;
    private shopping_cart_items_count: number = 0;

    async list_for_map(show_id: number, floorplan_id: number): Promise<{ success: boolean; booking_items?: BookingItem[]; errors?: any }> {
        try {
            const {
                data: {
                    booking_items,
                    vendors,
                    shared_vendors,
                    categories,
                    booked_booths_on_map_ts,
                    shopping_cart,
                    shopping_cart_items_ts,
                    shopping_cart_items_count,
                }
            } = await axios.get(`/${this.entity}/list_for_map`, {
                params: {
                    show_id,
                    floorplan_id,
                    booked_booths_on_map_ts: this.booked_booths_on_map_ts,
                    booked_booths_on_map_count: this.booked_booths_on_map_count,
                    shopping_cart_items_ts: this.shopping_cart_items_ts,
                    shopping_cart_items_count: this.shopping_cart_items_count,
                }
            });

            if (booking_items) {
                this.booking_items = booking_items.map((b: any) => BookingItem.from(b));
                this.vendors = uniqBy([...vendors, ...shared_vendors ?? []], v => v.id);
                this.categories = categories;
                this.booked_booths_on_map_ts = booked_booths_on_map_ts;
                this.booked_booths_on_map_count = this.booking_items.length;
                this.occupancy = {};
                // this.current_booking = null;
                for (const i of this.booking_items)
                    this.occupancy[i.booth_number] = [i.vendor_id, i.sharing_with_vendor_id]
                        .filter(id => !!id)
                        .map(id => this.vendors.find(v => v.id === id) ?? {id: id!});
            }

            if (shopping_cart) {
                this.current_booking = Booking.from(shopping_cart);
                this.shopping_cart_items_ts = shopping_cart_items_ts;
                this.shopping_cart_items_count = shopping_cart_items_count;
            }

            return {success: true, booking_items: this.booking_items};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async add_to_cart(booth_number: string, show_id?: number, floorplan_id?: number): Promise<{ success: boolean, errors?: ErrorForDisplay }> {
        try {
            show_id ??= ShowsGenericRepo.current_show?.id;
            floorplan_id ??= MapRepo.current_map?.id;
            if (!show_id || !floorplan_id) return {
                success: false,
                errors: new ErrorForDisplay('Wrong parameters provided')
            };

            const {data: {booking, jwt, companies}} = await axios.post('/booking/add_to_cart', {
                show_id,
                floorplan_id,
                booth_number
            });
            this.current_booking = Booking.from(booking);
            if (jwt)
                JwtRepo.jwt = jwt;
            if (companies)
                JwtRepo.companies = companies;

            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async add_to_booking(booking_id: number, booth_number: string, show_id?: number, floorplan_id?: number): Promise<{ success: boolean, booking?: Booking, errors?: ErrorForDisplay }> {
        try {
            show_id ??= ShowsGenericRepo.current_show?.id;
            floorplan_id ??= MapRepo.current_map?.id;
            if (!show_id || !floorplan_id) return {
                success: false,
                errors: new ErrorForDisplay('Wrong parameters provided')
            };

            const {data: {booking}} = await axios.post('/booking/add_to_booking', {
                show_id,
                floorplan_id,
                booth_number
            }, {params: {booking_id}});
            const booking_entity = Booking.from(booking);

            return {success: true, booking: booking_entity};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async add_or_update_service_in_cart(service_id: number, qty: number, show_id?: number, floorplan_id?: number): Promise<{ success: boolean, reason?: string; errors?: any }> {
        show_id ??= ShowsGenericRepo.current_show?.id;
        floorplan_id ??= MapRepo.current_map?.id;
        if (!show_id || !floorplan_id) return {success: false, reason: 'Wrong parameters provided'};
        try {
            const {data: {success, booking, reason}} = await axios.post('/booking/add_or_update_service_in_cart', {
                show_id,
                floorplan_id,
                service_id,
                qty,
            });
            this.current_booking = Booking.from(booking);

            return {success, reason};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async add_or_update_service_in_booking(service_id: number, qty: number, show_id?: number, floorplan_id?: number, booking_id?: number): Promise<{ success: boolean, booking?: Booking; errors?: ErrorForDisplay }> {
        if (!show_id || !floorplan_id) return {
            success: false,
            errors: new ErrorForDisplay('Wrong parameters provided')
        };
        try {
            const {
                data: {booking,}
            } = await axios.post('/booking/admin_add_or_update_service_in_booking', {
                show_id,
                floorplan_id,
                service_id,
                qty,
            }, {params: {booking_id}});

            const entity = Booking.from(booking);

            return {success: true, booking: entity};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async get_shopping_cart(show_id: number) {
        try {
            const {data: {booking}} = await axios.post('/booking/get_shopping_cart', {}, {params: {show_id}});
            this.current_booking = booking ? Booking.from(booking) : null;
        } catch (ex) {

        }
    }

    async remove_item(booking_id?: number, item_id?: number, booth_number?: string) {
        if (booth_number && !item_id)
            item_id = this.current_booking?.items.find(i => i.booth_number === booth_number)?.id;

        booking_id ??= this.current_booking?.id;

        if (!booking_id || !item_id) return;

        const {data: {booking}} = await axios.post('/booking/remove_item', {}, {params: {booking_id, item_id}});
        this.current_booking = Booking.from(booking);
        if (booth_number) delete this.occupancy[booth_number];
    }

    async edit_booking(booking_id: number, vendor_id: number, notes_admin: string) {
        await axios.post('/booking/edit_booking', {booking_id, vendor_id, notes_admin});
    }

    async create_paypal_order(payment_amount: number, booking_id: number, use_store_credit: boolean): Promise<{ success: boolean; paypal_order_id?: string; errors?: ErrorForDisplay }> {
        try {
            const {data: {paypal_order_id}} = await axios.post('/paypal/create_paypal_order', {
                booking_id,
                payment_amount,
                use_store_credit,
            });

            return {success: true, paypal_order_id};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async send_invoice(booking_id: number) {
        await axios.post('/booking/send_invoice', {}, {params: {booking_id}});
    }

    async approve_paypal_order(paypal_order_id: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            const {data: {company_credit_info}} = await axios.post('/paypal/approve', {}, {params: {paypal_order_id}});
            if (company_credit_info) {
                JwtRepo.store_credit = company_credit_info.store_credit;
                JwtRepo.total_overpayments = company_credit_info.total_overpayments;
            }
            this.current_booking = null;
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async pay_via_store_credit(booking_id: number, amount: number): Promise<{ success: boolean, errors?: ErrorForDisplay }> {
        try {
            const {data: {company_credit_info}} = await axios.post('/booking/pay_via_store_credit', {}, {
                params: {
                    booking_id,
                    amount
                }
            });
            if (company_credit_info) {
                JwtRepo.store_credit = company_credit_info.store_credit;
                JwtRepo.total_overpayments = company_credit_info.total_overpayments;
            }
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async get_booking(booking_id: number): Promise<{ success: boolean, booking?: Booking, errors?: ErrorForDisplay }> {
        try {
            const {data: {booking}} = await axios.get('/booking/get_booking', {params: {booking_id}});
            const booking_typed = Booking.from(booking);
            return {success: true, booking: booking_typed};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async remove_payment(booking_id: number, payment_id: number): Promise<{ success: boolean, errors?: ErrorForDisplay }> {
        try {
            await axios.post('/booking/remove_payment', {}, {params: {booking_id, payment_id}});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async refund_payment(payment_id: number): Promise<{ success: boolean, errors?: ErrorForDisplay }> {
        try {
            await axios.post('/paypal/refund_payment', {}, {params: {payment_id}});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async set_order_status(booking_id: number, status: string): Promise<{ success: boolean, booking?: Booking, errors?: ErrorForDisplay }> {
        try {
            const {data: {booking}} = await axios.post('/booking/set_order_status', {}, {params: {booking_id, status}});
            const booking_entity = Booking.from(booking);

            return {success: true, booking: booking_entity};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async save(e: LikeableEntity): Promise<{ success: boolean, booking?: Booking, errors?: ErrorForDisplay }> {
        try {
            const post_body = e.prepare_for_save();
            const {data: {booking}} = await axios.post(`/${this.entity}/save`, post_body);
            const booking_entity = Booking.from(booking);
            return {success: true, booking: booking_entity};
        } catch (ex) {
            console.log(ex)
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    /*reset() {
        console.log('reset')
        this.booking_items = [];
        this.vendors = [];
        this.categories = [];
        this.occupancy = {};
        this.current_booking = null;
        this.shopping_cart_items_ts = 0;
        this.shopping_cart_items_count = 0;
    }*/

    async add_or_update_manual_payment(booking_id: number, id: number, amount: number, notes?: string): Promise<{ success: boolean, booking?: Booking, errors?: ErrorForDisplay }> {
        try {
            const {data: {booking}} = await axios.post('/booking/add_or_update_manual_payment', {
                id,
                amount,
                notes
            }, {params: {booking_id}});

            const booking_entity = Booking.from(booking);

            return {success: true, booking: booking_entity};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async bulk_send_invoices(show_id: number): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/booking/bulk_send_invoices', {}, {params: {show_id}});

            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async set_shared_vendor(booking_item_id: number, shared_vendor_id: number | null): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/booking/set_shared_vendor', {}, {params: {booking_item_id, shared_vendor_id}});

            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async pdf_receipt(booking_id: number): Promise<{ temp_data_id?: string; errors?: ErrorForDisplay }> {
        try {
            const {data: {id}} = await axios.post('/booking/get_pdf_receipt', {}, {params: {booking_id}});
            return {temp_data_id: id};
        } catch (ex) {
            return {errors: new ErrorForDisplay(ex)};
        }
    }

    async move_payment(payment_id: number, target_booking_id: number): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/booking/move_payment', {}, {params: {payment_id, target_booking_id}});

            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }
}

export const BookingsRepo = Vue.observable(new _BookingsRepo());