











































import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import BoothTypeFullEditor from "@/components/editors/BoothTypeFullEditor.vue";
import Booth from "@/classes/floor-map/Booth";
import {BFormInput} from "bootstrap-vue";

@Component({
             components: {BoothTypeFullEditor}
           })
export default class BoothQuickPropsEditor extends Vue {

  @Prop()
  booth!: Booth;

  @Prop()
  booth_letter!: string;

  @Ref()
  txt_price!: BFormInput;

  private days_options = Array.from(Array(10).keys()).map(n => ({
    value: n + 1,
    text : `${n + 1} ${n ? 'Days' : 'Day'}`
  }));

  price: string = '';

  price_overridden: boolean = false;

  async mounted() {
    this.price = String(this.booth.price);
    this.price_overridden = this.booth.price_overridden;
  }

  private async price_changing(value: string) {
    if (value === '') {
      this.booth.price_override = null;
      this.price_overridden = false;
    } else {
      this.booth.price_override = Number(value);
      this.price_overridden = true;
    }

    // await this.$nextTick()

    this.price_overridden = this.booth.price_overridden;

    await this.$nextTick()

    this.price = String(this.booth.price);
  }

  private async price_changed(event?: string) {
    this.booth.price = this.price === '' ? null : Number(this.price);
    await this.$nextTick()
    if (event != 'blur') {
      this.price_overridden = this.booth.price_overridden;
    }
  }

  private async price_overridden_changed(v: boolean) {
    this.booth.price_override = this.price_overridden ? this.booth.booth_type.price : null;

    await this.$nextTick()

    this.price = String(this.booth.price);

    if (this.price_overridden) this.txt_price.focus();
  }

}
