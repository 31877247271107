





































































import {Component} from 'vue-property-decorator';
import {BoothType} from "@/classes/floor-map/BoothType";
import ReImageUploader from "@/components/common/re-image-uploader.vue";
import {BForm, BModal} from "bootstrap-vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import EntityEditor from "@/components/generic/EntityEditor.vue";
import {EventBus} from "@/classes/common/EventBus";

@Component(
    {
      components: {ReImageUploader}
    }
)
export default class BoothTypeFullEditor extends EntityEditor {
  $refs!: {
    modal: BModal,
    form: BForm,
    uploader: ReImageUploader
  }

  repo = BoothTypesGenericRepo;
  TEntity = BoothType

  protected entity: (BoothType | null) = null;
  protected orig_entity!: BoothType;

  private get booth_type() {
    return this.entity!;
  }

  private days_options = Array.from(Array(10).keys()).map(n => ({
    value: n + 1,
    text : `${n + 1} ${n ? 'Days' : 'Day'}`
  }));

  protected get entity_single_name(): string {
    return 'Booth Type';
  }

  protected setup_entity() {
    this.booth_type.copyTo(this.orig_entity);
    if (!this.orig_entity.letter_index)
      this.orig_entity.letter_index = ShowsGenericRepo.current_show!.get_free_letter_index();
  }

  protected async after_save(): Promise<void> {
    await BoothTypesGenericRepo.load_booth_types();
    BoothTypesGenericRepo.remap();
    ShowsGenericRepo.current_show!.update_booth_sizes_from_booth_types();
    EventBus.$emit('remount-canvas');
  }

}
