import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import ShowsComp from "@/components/pages/ShowsComp.vue";
import BoothTypesComp from "@/components/pages/Map/BoothTypesComp.vue";
import MapPagesGroup from "@/components/pages/Map/MapPagesGroup.vue";
import LayoutPage from "@/components/pages/Map/LayoutPage.vue";
import PricingPage from "@/components/pages/Map/PricingPage.vue";
import FloorPlanQuickEditor from "@/components/editors/FloorPlanQuickEditor.vue";
import PricingQuickInfo from "@/components/common/PricingQuickInfo.vue";
import {MapRepo} from "@/classes/repos/MapRepo";
import ImportBoothTypesComp from "@/components/pages/Map/ImportBoothTypesComp.vue";
import LoginPage from "@/components/pages/LoginPage.vue";
import ActivateAccount from "@/components/pages/Account/ActivateAccount.vue";
import App_Authenticated from "@/components/common/App_Authenticated.vue";
import {default_title} from "@/classes/common/Const";
import {startCase} from "lodash";
import Confirmation from "@/components/pages/Account/Confirmation.vue";
import UserFrontendEditor from "@/components/editors/UserFrontendEditor.vue";
import AccountPagesGroup from "@/components/pages/Account/AccountPagesGroup.vue";
import ForgotPassword from "@/components/pages/Account/ForgotPassword.vue";
import FloorplanSetupPage from "@/components/pages/Map/FloorplanSetupPage.vue";
import AccountOrder from "@/components/pages/Account/AccountOrder.vue";
import Bookings from "@/components/Reservations/Bookings.vue";
import AdminOrder from "@/components/Reservations/AdminOrder.vue";
import Sellable_Services from "@/components/Reservations/Sellable_Services.vue";
import VendorProfile from "@/components/pages/Account/VendorProfile.vue";
import CheckoutVendorProfile from "@/components/pages/Checkout/CheckoutVendorProfile.vue";
import CheckoutPayment from "@/components/pages/Checkout/CheckoutPayment.vue";
import CheckoutThankYou from "@/components/pages/Checkout/CheckoutThankYou.vue";
import Vendors from "@/components/pages/Vendors/Vendors.vue";
import AdminVendorProfile from "@/components/pages/Vendors/AdminVendorProfile.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import UsersGenericComp from "@/components/generic/UsersGenericComp.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import NotFoundComponent from "@/components/common/NotFoundComponent.vue";
import SettingsTermsOfService from "@/components/settings/SettingsTermsOfService.vue";
import Register from "@/components/pages/Account/Register.vue";
import CompanyUsers from "@/components/vendor/CompanyUsers.vue";
import VendorsReport from "@/components/reports/VendorsReport.vue";
import App from "@/App.vue";
import CompaniesComp from "@/components/pages/Account/CompaniesComp.vue";
import WaitlistComp from "@/components/floor-map/WaitlistComp.vue";
import EmailSettings from "@/components/settings/EmailSettings.vue";

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    // {name: 'home', path: '/', component: App_Authenticated,},
    {name: 'login', path: '/login', component: LoginPage,},
    {
        // name     : 'app_authenticated',
        path     : '/',
        component: App_Authenticated,
        meta     : {requiresAuth: false},
        children : [
            {name: 'shows', path: '', component: ShowsComp,},
            {
                name     : 'map',
                path     : '/shows/:slug?/map/:fp_slug?',
                meta     : {requiresAuth: false, group_name: 'map pages'},
                component: MapPagesGroup,
                // props: true,
                children: [
                    {
                        name     : 'floorplan', path: 'floorplan', props: true,
                        component: FloorplanSetupPage,
                        meta     : {group_name: 'map pages'}
                    },
                    {
                        name      : 'layout', path: 'layout', props: true,
                        components: {
                            default      : LayoutPage,
                            control_panel: FloorPlanQuickEditor,
                        },
                        meta      : {group_name: 'map pages', update_bookings_map: true}
                    },
                    {
                        name      : 'choose_booths', path: 'choose_booths', props: true,
                        components: {
                            default      : LayoutPage,
                            control_panel: FloorPlanQuickEditor,
                        },
                        meta      : {group_name: 'map pages', update_bookings_map: true}
                    },
                    {
                        name: 'pricing', path: 'pricing', components:
                            {
                                default      : PricingPage,
                                control_panel: PricingQuickInfo
                            },
                        meta: {group_name: 'map pages', update_bookings_map: true}
                    },
                    {
                        name     : 'booths', path: 'booths',
                        component: BoothTypesComp,
                        meta     : {group_name: 'map pages'}
                    },
                    {
                        name     : 'import_booths', path: 'import_booths',
                        component: ImportBoothTypesComp,
                        meta     : {group_name: 'map pages'}
                    },
                ]
            },
            {name: 'users', path: '/users', meta: {requiresAuth: true}, component: UsersGenericComp,},
            {
                path     : '/account/edit',
                meta     : {requiresAuth: true},
                component: AccountPagesGroup,
                children : [
                    {
                        name     : 'edit_account',
                        path     : '',
                        component: UserFrontendEditor,
                    },
                    {
                        name     : 'my_bookings',
                        path     : '/account/my_bookings',
                        component: Bookings,
                    },
                    {
                        name     : 'booking',
                        path     : '/account/booking/:booking_id',
                        component: AccountOrder,
                        props    : route => ({booking_id: Number(route.params.booking_id)}),
                    },
                    {
                        name     : 'thank_you',
                        path     : '/account/booking/:booking_id/thank_you',
                        component: AccountOrder,
                        props    : route => ({booking_id: Number(route.params.booking_id), thank_you: true})
                    },
                    {
                        name     : 'account_waitlist',
                        path     : '/account/waitlist',
                        component: WaitlistComp,
                        meta     : {title: 'Waitlist'}
                    },
                    {
                        name     : 'new_vendor_profile',
                        path     : '/account/profile/new',
                        component: VendorProfile,
                        meta     : {show_vendor_profile_menu: true},
                        props    : {company_id: 0},
                    },
                    {
                        name     : 'vendor_profile',
                        path     : '/account/profile/:company_id?',
                        component: VendorProfile,
                        meta     : {show_vendor_profile_menu: true},
                        props    : route => ({company_id: Number(route.params.company_id ?? JwtRepo.vendor_id ?? 0)})
                    },
                    {
                        name     : 'manage_company_users',
                        path     : '/account/users',
                        component: CompanyUsers,
                    },
                    {
                        name     : 'companies',
                        path     : '/account/companies',
                        component: CompaniesComp,
                    },
                ]
            },
            {
                name     : 'admin_waitlist',
                path     : '/waitlist',
                component: WaitlistComp,
                meta     : {requiresAuth: true, title: 'Waitlist'},
            },
            {name: 'orders', path: '/orders', component: Bookings, meta: {requiresAuth: true}},
            {
                name     : 'sellable_services',
                path     : '/orders/sellable_services',
                component: Sellable_Services,
                meta     : {requiresAuth: true, group_name: 'orders'}
            },
            {
                name     : 'order',
                path     : '/order/:booking_id',
                component: AdminOrder,
                props    : r => ({booking_id: Number(r.params.booking_id)}),
                meta     : {requiresAuth: true, group_name: 'orders'}
            },
            {
                name     : 'new_order',
                path     : '/order/new',
                component: AdminOrder,
                props    : {booking_id: 0},
                meta     : {requiresAuth: true, group_name: 'orders'}
            },

            {name: 'exhibitors', path: '/exhibitors', component: Vendors, meta: {requiresAuth: true}},
            {
                name : 'admin_vendor_profile', path: '/exhibitor/profile/:vendor_id', component: AdminVendorProfile,
                props: true,
                meta : {requiresAuth: true, title: 'Exhibitor Profile', show_vendor_profile_menu: true}
            },

            {
                name     : 'booked_vendor_report',
                path     : '/booked_vendor_report',
                component: VendorsReport,
                meta     : {requiresAuth: true, group_name: 'reports'}
            },

            //checkout
            {
                name     : 'checkout_setup_profile',
                path     : '/checkout/registration',
                component: CheckoutVendorProfile,
                meta     : {group_name: 'checkout', show_vendor_profile_menu: true}
            },
            {
                name     : 'checkout_payment',
                path     : '/checkout/payment',
                component: CheckoutPayment,
                meta     : {group_name: 'checkout'}
            },
            {
                name     : 'checkout_thank_you_anonymous',
                path     : '/checkout/thank_you/:booking_id',
                component: CheckoutThankYou,
                props: route => ({booking_id: Number(route.params.booking_id)}),
                meta: {group_name: 'checkout', title: 'Thank You'},
            },

            {
                name: 'terms_of_service',
                path: '/settings/terms_of_service',
                component: SettingsTermsOfService,
                meta: {group_name: 'settings',},
            },
            {
                name: 'email_settings',
                path: '/settings/email_settings',
                component: EmailSettings,
                meta: {group_name: 'settings',},
            },


        ]

    },

    {name: 'register', path: '/register', component: Register,},
    {name: 'activate_account', path: '/account/activate/:token', component: ActivateAccount, props: true,},
    {name: 'forgot_password', path: '/account/forgot_password', component: ForgotPassword,},
    {name: 'password_reset', path: '/account/password_reset/:token', component: ActivateAccount, props: true,},
    // {name: 'password_reset', path: '/account/password_reset/:token', component: PasswordReset, props: true, },
    {name: 'confirmation', path: '/account/confirmation', component: Confirmation, props: true,},


    // {path: '/layout', component: FloorPlanEditor, name: 'layout', props: true},
    // {path: '/booths', component: BoothTypesComp, name: 'booth_types'},
    // {path: '/people', component: , name: 'booth_types'},

    {path: '/:catchAll(.*)', component: NotFoundComponent, name: 'NotFound'},
];

const router = new VueRouter({
                                 mode: 'history',
                                 base: process.env.BASE_URL,
                                 routes,

                             });

router.beforeEach((to, from, next) => {
    App.clear_notifications();
    window.scrollTo(0, 0);

    /*if (!JwtRepo.is_logged_in) {
        const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
        if (requiresAuth) {
            // console.log('redirecting to login');
            next({name: 'login'});
            return;
        }
    }*/

    if (from.matched.some(r => r.meta.group_name == 'map pages') && !to.matched.some(r => r.meta.group_name == 'map pages'))
        //leaving Map pages group
        if (!!ShowsGenericRepo.current_show && ShowsGenericRepo.current_show_has_changes)
            if (!confirm('There are unsaved changes. Are you sure?'))
                return next(false)

    MapRepo.selected_map_objects = [];
    MapRepo.selected_wrap_objects = [];

    Vue.nextTick(() => {
        document.title = to.meta?.title ?? startCase(to.name ?? default_title);
        document.body.setAttribute('page', to.meta?.css_class ?? to.name ?? '');
        // document.body.setAttribute('fullPath', to.fullPath ?? '');

    });
    next();
})

export default router
