import XDate from "xdate";
import FloorMap from "@/classes/floor-map/FloorMap";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {MapRepo} from "@/classes/repos/MapRepo";
import {BoothType} from "@/classes/floor-map/BoothType";
import Booth from "@/classes/floor-map/Booth";
import {ReHelper} from "@/classes/common/ReHelper";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import router from "@/router";
import MultiTableDiscount from "@/classes/floor-map/MultiTableDiscount";

export default class Show {
    id: number;
    name: string;
    slug: string;
    imageURL: string;
    location: string;
    start_date: XDate;
    end_date: XDate;
    contact_name?: string;
    contact_number?: string;
    publish_status: string;
    waitlist_enabled: boolean;

    maps?: FloorMap[];
    multi_table_discounts: MultiTableDiscount[];

    get is_not_empty(): boolean {
        return !!this.maps?.length;
    }

    /*private static UID_base = 0;
    private UID: number;*/

    constructor(id: number,
                name: string,
                slug: string,
                location: string,
                start_date: string,
                end_date: string,
                publish_status: string,
                waitlist_enabled: boolean,
                maps?: FloorMap[],
                // booth_types?: BoothType[],
                imageURL?: string,
                contact_name?: string,
                contact_number?: string,
                multi_table_discounts?: MultiTableDiscount[]) {
        this.id = id;
        this.name = name;
        this.slug = slug;
        this.imageURL = imageURL ?? '/images/Placeholder.png';
        this.location = location;
        this.start_date = new XDate(start_date);
        this.end_date = new XDate(end_date);
        this.publish_status = publish_status;
        this.waitlist_enabled = waitlist_enabled;
        this.maps = maps;
        this.multi_table_discounts = multi_table_discounts ?? [];
        if (contact_name)
            this.contact_name = contact_name;
        if (contact_number)
            this.contact_number = contact_number;

        // this.UID = ++Show.UID_base;
        // this.booth_types = booth_types;

        // booth_types?.filter(t => t.is_favourite).forEach((t, n) => (t.letter_index = String.fromCharCode(65 + n)));
    }

    static from(o: any): Show {
        const show: Show = new Show(
            o.id,
            o.name,
            o.slug,
            o.location,
            o.start_date,
            o.end_date,
            o.publish_status,
            o.waitlist_enabled,
            undefined, //booth types must be loaded first
            // o.booth_types?.map((x: any) => BoothType.from(x)),
            o.imageURL,
            o.contact_name,
            o.contact_number,
            o.multi_table_discounts?.map((x: any) => MultiTableDiscount.from(x)),
        );

        return show;
    }

    copyTo(sh: Show) {
        sh.name = this.name;
        sh.slug = this.slug;
        sh.location = this.location;
        sh.start_date = this.start_date;
        sh.end_date = this.end_date;
        sh.publish_status = this.publish_status;
        sh.waitlist_enabled = this.waitlist_enabled;
        sh.imageURL = this.imageURL;
        sh.contact_name = this.contact_name;
        sh.contact_number = this.contact_number;
        sh.multi_table_discounts = this.multi_table_discounts;
    }

    maps_loaded(): boolean {
        return !!this.maps;
    }

    async load_details_and_select(json?: string) {
        ShowsGenericRepo.select_show(this);
        await BoothTypesGenericRepo.load_booth_types();
        if (json) {
            this.maps = JSON.parse(json).maps?.map((m: any) => FloorMap.from(m)) ?? [];
        }
        MapRepo.current_map = this.maps?.[0];
    }

    prepare_for_save() {
        if (!this.maps?.length && router.currentRoute.meta?.group_name === 'map pages') throw 'Cannot save empty show';

        const maps_with_missing_fp_ids = this.maps?.filter(m => !m.id);
        if (maps_with_missing_fp_ids?.length) {
            let max_id = 0;
            this.maps?.forEach(m => max_id = (m.id ?? 0) > max_id ? m.id : max_id);
            maps_with_missing_fp_ids.forEach(m => m.id = ++max_id);
        }

        return {
            id              : this.id,
            name            : this.name,
            slug            : this.slug,
            imageURL        : this.imageURL,
            location        : this.location,
            start_date      : this.start_date.toString('yyyy-MM-dd'),
            end_date        : this.end_date.toString('yyyy-MM-dd'),
            contact_name    : this.contact_name,
            publish_status  : this.publish_status,
            waitlist_enabled: this.waitlist_enabled,
            contact_number  : this.contact_number,
            json            : this.maps ? JSON.stringify(
                {
                    // contact_name  : this.contact_name,
                    // contact_number: this.contact_number,
                    maps: this.maps
                }
            ) : undefined
        };
    }

    clone(): Show {
        return Show.from(this);
    }

    static default(): Show {
        return new Show(
            0,
            '',
            '',
            '',
            XDate.today().toString('yyyy-MM-dd'),
            XDate.today().toString('yyyy-MM-dd'),
            'Private',
            false,
            [],
        )
    }

    get short_description() {
        return `${this.name} (${this.location} ${this.dates})`;
    }

    get dates(): string {
        return ReHelper.dates(this.start_date, this.end_date);
    }

    get_free_letter_index(): string {
        let letters = new Set([...Array(26).keys()].map(n => String.fromCharCode(65 + n)));
        BoothTypesGenericRepo.all_booth_types.forEach(t => letters.delete(t.letter_index));
        return [...letters][0];
    }

    all_booths(types: ('Booth' | 'ComplexBooth')[] = ['Booth', 'ComplexBooth']): Booth[] {
        return this.maps?.flatMap(m => m.all_booths(types)) ?? [];
    }

    assign_letter_index(booth_type: BoothType) {
        booth_type.letter_index = '';
        const similar_bt = BoothTypesGenericRepo.all_booth_types.find(t => t.type_id === booth_type.type_id && t !== booth_type);
        booth_type.letter_index = similar_bt?.letter_index ?? this.get_free_letter_index();
    }

    assigned_indices(letter_index: string) {
        return this.maps?.flatMap(m => m.all_booths().filter(b => b.booth_type.letter_index === letter_index)).map(b => b.numeric_index) ?? [];
    }

    assigned_indices_set(letter_index: string): Set<number> {
        return new Set(this.assigned_indices(letter_index));
    }

    available_indices(letter_index?: string) {
        if (!letter_index) return [];

        const assigned_indices = this.assigned_indices(letter_index);
        const assigned_indices_set = new Set(assigned_indices);
        let assigned_indices_cnt = assigned_indices.length;
        let available_indices = [];

        let n = 1;
        for (; assigned_indices_cnt; n++)
            if (assigned_indices_set.has(n))
                assigned_indices_cnt--;
            else available_indices.push(n);

        available_indices.push(...ReHelper.range(n, n + 9));

        return available_indices;
    }

    assign_numeric_index(booth: Booth) {
        booth.numeric_index = 0;
        const assigned_indices = this.assigned_indices_set(booth.booth_type.letter_index);
        let i = 1;
        while (assigned_indices.has(i)) i++;
        booth.numeric_index = i;
    }

    get_new_floorplan_id() {
        return (this.maps?.length ?? 0) + 1;
    }

    update_booth_sizes_from_booth_types() {
        this.all_booths().forEach(b => b.update_size_from_booth_type());
    }
}