




















































import {Component, Vue} from 'vue-property-decorator';
import FloorPlan from "@/classes/floor-map/FloorPlan";
import {EventBus} from "@/classes/common/EventBus";
import {MapRepo} from "@/classes/repos/MapRepo";
import {MapLibrariesGenericRepo} from "@/classes/repos/MapLibrariesGenericRepo";
import FloorMapLib from "@/classes/floor-map/FloorMapLib";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import App from "@/App.vue";

@Component<FloorPlanQuickEditor>(
    {}
)
export default class FloorPlanQuickEditor extends Vue {
  private get map() {
    return MapRepo.current_map!;
  }

  private get slug() {
    return ShowsGenericRepo.current_show?.slug ?? '';
  }

  private get fp_slug() {
    return MapRepo.current_map?.floor_plan?.slug;
  }

  private get floor_plan(): FloorPlan | undefined {
    return this.map?.floor_plan;
  }

  private async reset_map() {
    const confirmed: boolean = await this.$bvModal.msgBoxConfirm(`Delete all the map objects?`);
    if (confirmed) EventBus.$emit('reset_map');
  }

  private async save_booth_layout() {
    if (this.map) {
      const show = ShowsGenericRepo.current_show!;
      const map = this.map.clone().make_booth_types_internal();
      let floor_map_lib = new FloorMapLib(0, show.name, show.location, show.start_date.toString('yyyy-MM-dd'), show.end_date.toString('yyyy-MM-dd'), map);
      const {success, id, errors} = await MapLibrariesGenericRepo.save(floor_map_lib);
      if (success) {
        App.notify('Booth Layout saved')
        const dataURL = MapRepo.active_canvas?.get_png();
        if (dataURL)
          await MapLibrariesGenericRepo.save_map_image(id!, dataURL);
        await MapLibrariesGenericRepo.list();
      } else {
        App.notify(errors!.single_err_msg, 'danger');
      }
    }
  }
}
