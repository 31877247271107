import 'reflect-metadata';
import Vue from 'vue'
import router from './router'
import App from "@/App.vue";

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {AppRepo} from "@/classes/repos/AppRepo";
import {ReHelper} from "@/classes/common/ReHelper";
import axios from "axios";
import {base_URL} from "@/classes/common/Const";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import {UsersRepo} from "@/classes/repos/UsersRepo";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.filter('format_date', ReHelper.format_date);
Vue.filter('format_datetime', ReHelper.format_datetime);
Vue.filter('format_money', ReHelper.format_money);
Vue.filter('format_camelcase_to_sentence', ReHelper.camelcase_to_sentence);


Vue.config.productionTip = false;
Vue.prototype.$repo = AppRepo;
Vue.prototype.$event_bus = new Vue();

axios.defaults.baseURL = base_URL;
axios.interceptors.response.use((response) => response, async (err) => {
    const a = axios.create();
    try {
        await a.post('/app/client_error', {
            message : err.message,
            status  : String(err.response.status),
            url     : err.request.responseURL,
            stack   : err.stack,
            request : err.config.data,
            method  : err.config.method,
            response: JSON.stringify(err.response.data)
        }, {headers: {authorization: JwtRepo.auth_header.Authorization}});
    } catch (e) {
    }
    throw err;
});

window.addEventListener('error', async (ex: ErrorEvent) => {
                            await axios.post('/app/client_error', {
                                message: ex.message,
                                url    : location.href,
                                stack  : ex.error?.stack
                            })
                        }
);

Vue.config.errorHandler = async (err, vm, info) => {
    await axios.post('/app/client_error', {message: err.message, url: location.href, stack: err.stack});
    console.error(err);
};

AppRepo.load_settings().then(async r => {
    AuthRepo.init();

    new Vue({
                router,
                render: h => h(App)
            }).$mount('#app');

    if (JwtRepo.is_admin)
        await UsersRepo.load_user_settings();

    if (!!JwtRepo.vendor_id) {
        const {companies, user_data, errors} = await UsersRepo.get_connected_companies();
        JwtRepo.first_name = user_data?.first_name ?? null;
        JwtRepo.last_name = user_data?.last_name ?? null;
    } else if (JwtRepo.is_registered_user) {
        const {user_data, errors} = await UsersRepo.get_user_data();
        JwtRepo.first_name = user_data?.first_name ?? null;
        JwtRepo.last_name = user_data?.last_name ?? null;
    }

    await BookingsRepo.load_paypal_buttons_script();

});



