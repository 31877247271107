




























import {Component, Ref, Vue} from 'vue-property-decorator';
import {AppRepo} from "@/classes/repos/AppRepo";
import App from '@/App.vue';
import {BForm} from "bootstrap-vue";
import {uniq} from "lodash-es";

@Component<EmailSettings>(
    {}
)
export default class EmailSettings extends Vue {
  private emails_from_address: string | null = null;
  private admin_emails_recipients: string[] = [];

  @Ref()
  private form!: BForm;

  async mounted() {
    const settings_array = await AppRepo.get_settings(['emails_from_address', 'admin_emails_recipients']);
    const settings = Object.fromEntries(settings_array.map(s => [s.name, s.value]));
    this.emails_from_address = settings.emails_from_address ?? '';
    this.admin_emails_recipients = settings.admin_emails_recipients?.split(',') ?? [];
  }

  async save(event: Event) {
    if (!this.form.checkValidity()) {
      event.preventDefault();
      this.form.reportValidity();
      return;
    }

    const settings = {
      emails_from_address: this.emails_from_address ?? '',
      admin_emails_recipients: uniq(this.admin_emails_recipients.filter(x => !!x)).join(',')
    };

    await AppRepo.save_settings(settings);

    App.notify('Email settings saved');
  }

}
