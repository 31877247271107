






























































import {Component} from 'vue-property-decorator';
import EntityEditor from "@/components/generic/EntityEditor.vue";
import Show from "@/classes/floor-map/Show";
import ReImageUploader from "@/components/common/re-image-uploader.vue";
import {BForm} from "bootstrap-vue";
import XDate from "xdate";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<ShowGenericEditor>(
    {
      components: {ReImageUploader}
    }
)
export default class ShowGenericEditor extends EntityEditor {
  $refs!: {
    form: BForm
  }

  repo = ShowsGenericRepo;
  TEntity = Show;

  private get show(): Show {
    return <Show>this.entity;
  }

  private get orig_show(): Show {
    return <Show>this.orig_entity;
  }

  private get start_date(): string {
    return this.show!.start_date.toString('yyyy-MM-dd');
  }

  private set start_date(v) {
    this.show!.start_date = new XDate(v);
  }

  private get start_date_valid(): boolean | null {
    if (this.show!.start_date.valueOf() == this.orig_show!.start_date.valueOf()) return null;
    else return this.show!.start_date <= this.show!.end_date;
  }

  private get end_date_valid(): boolean | null {
    if (this.show!.end_date.valueOf() == this.orig_show!.end_date.valueOf()) return null;
    else return this.show!.start_date <= this.show!.end_date;
  }


  private get end_date(): string {
    return this.show!.end_date.toString('yyyy-MM-dd');
  }

  private set end_date(v) {
    this.show!.end_date = new XDate(v);
  }

  protected check_validity_additional() {
    return this.start_date_valid !== false && this.end_date_valid !== false;
  }

  protected setup_entity() {
    this.show.copyTo(this.orig_entity as Show);
  }
}
