




import {Component, Prop, Watch} from 'vue-property-decorator';
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import {fabric} from "fabric";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component
export default class ReFabricImage extends ReFabricObject {
  fjs_object?: fabric.Image;

  @Prop()
  image_url!: string;

  @Prop()
  selectable?: boolean;

  private img_w?: number;
  private img_h?: number;

  private offset_x: number = 0;
  private offset_y: number = 0;

  protected create_fjs_object(callback: () => void) {
    const imgOptions: any = {
      left        : this.loc.left,
      top         : this.loc.top,
      angle       : this.angle ?? 0,
      hasControls : this.has_controls,
      lockRotation: !this.allow_resizing || this.lock_movements,
      // hoverCursor: 'url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/happy.png"), auto',
      evented: this.evented,
    };
    if (this.selectable ?? true) {
      imgOptions['selectable'] = true;
    } else {
      imgOptions['selectable'] = false;
      imgOptions['hoverCursor'] = 'default';
    }
    if (this.bordered ?? false) {
      imgOptions['strokeWidth'] = MapRepo.booth_edited_stroke_width;
      imgOptions['stroke'] = MapRepo.booth_edited_stroke_color;
    }
    const self = this;
    fabric.Image.fromURL(this.image_url, (oImg) => {
      self.fjs_object = oImg;
      self.img_w = oImg.width;
      self.img_h = oImg.height;

      if (this.loc.scaleX === 1 && this.loc.scaleY === 1) { //scale hasn't been calculated yet
        const scaleX = self.loc.width / oImg.width!;
        const scaleY = self.loc.height / oImg.height!;
        const scale = Math.min(scaleX, scaleY);

        if (scaleX > scaleY) { //need to center the image horizontally
          self.offset_x = (self.loc.width - scale * oImg.width!) / 2;
          self.offset_y = 0;
        } else if (scaleY > scaleX) { //need to center the image vertically
          self.offset_x = 0;
          self.offset_y = (self.loc.height - scale * oImg.height!) / 2;
        } else self.offset_x = self.offset_y = 0;

        oImg.set({scaleX: scale, scaleY: scale});
      } else
        oImg.set({scaleX: this.loc.scaleX, scaleY: this.loc.scaleY}); //scale calculated already

      oImg.set({top: self.loc.top! + self.offset_y, left: self.loc.left! + self.offset_x});

      (window as any)['fjs_image'] = oImg;
      // console.log('image loaded', self.image_url, `${oImg.width} x ${oImg.height}`)
      if (self.lowest_layer) {
        self.fjs_object?.sendToBack();
      }
      if (self.highest_layer) {
        self.fjs_object?.bringToFront();
      }
      this.$emit('image_loaded');
      callback();
    }, imgOptions);
  }

  @Watch('selectable')
  selectable_changed() {
    this.fjs_object?.set({selectable: this.selectable});
  }

}
