
































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Booking from "@/classes/bookings/Booking";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import BookingItem from "@/classes/bookings/BookingItem";
import ButtonNewPayment from "@/components/Reservations/ButtonNewPayment.vue";
import ExportPdfReceipt from "@/components/Reservations/ExportPdfReceipt.vue";

@Component<AccountOrder>(
    {
      components: {ExportPdfReceipt, ButtonNewPayment}
    }
)
export default class AccountOrder extends Vue {
  @Prop()
  booking_id!: number;

  @Prop({default: false})
  thank_you!: boolean;

  private booking: Booking | null = null;

  private get items_sorted(): BookingItem[] {
    return this.booking?.items.sort((a, b) => (a.show_id - b.show_id) || (a.floorplan_id - b.floorplan_id)) ?? [];
  }

  @Watch('booking_id')
  private async load_booking() {
    this.booking = (await BookingsRepo.get_booking(this.booking_id)).booking ?? null;
  }

  private async mounted() {
    await this.load_booking();

  }


}
