






import {Component} from 'vue-property-decorator';
import ICanvasParent from "@/components/interfaces/ICanvasParent";
import {fabric} from "fabric";
import ICanvasChild from "@/components/interfaces/ICanvasChild";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import {IEvent} from "fabric/fabric-impl";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component<ReFabricGroup>(
    {}
)
export default class ReFabricGroup extends ReFabricObject implements ICanvasParent, ICanvasChild {
  fjs_object?: fabric.Group;

  private child_objects: ReFabricObject[] = [];

  private MapRepo = MapRepo;

  /*  render() {
      return this.$slots.default
    }*/

  private beforeMount() {
    // console.log(`${this.constructor.name}.beforeMount (${this.debug_name}):`);
    this.create_fjs_object(() => {
    });
  }

  protected async initialize() {
    /*console.log(`${this.constructor.name}.initialize (${this.debug_name}):`);
    await this.$nextTick();
    this.create_fjs_object(() => {
    });*/
  }

  addChild(ch: ReFabricObject): void {
    // console.log(`${this.constructor.name}.addChild (${ch.debug_name} to ${this.debug_name}):`, this.loc.toString(), this.angle);
    this.child_objects.push(ch);

    if (this.child_objects.length == this.$children.length) { //all children ready
      this.child_objects.forEach(o => {
        this.fjs_object!.addWithUpdate(o.fjs_object!);
      });
      // console.log(`${this.constructor.name}.addChild: ${this.child_objects.length} objects added (${this.child_objects.map(o => o.debug_name).join(',  ')})`);

      if (this.angle) {
        this.fjs_object!.rotate(this.angle);
        this.fjs_object!.set({top: this.loc.top, left: this.loc.left});
        this.fjs_object!.setCoords();
        this.$emit('render_all');
        // console.log(`${this.constructor.name}.addChild - rotate (${this.debug_name}):`, this.loc.toString(), this.angle);
      }

      this.fjs_object!.set({top: this.loc.top, left: this.loc.left});
      this.fjs_object!.set({width: this.loc.width, height: this.loc.height});

      /*const curr_w = this.fo_width;
      const curr_h = this.fo_height;
      if (curr_w != this.loc.width || curr_h != this.loc.height) {
        debugger
        const dh = (curr_h - this.loc.height) / 2, dw = (curr_w - this.loc.width) / 2;
        this.fo_left -= dh * this.sin_a;
        this.fo_top += dh * this.cos_a;
      }
      this.fo_left += (curr_w - this.loc.width) / 2;
      if (curr_h != this.loc.height)
        this.fo_top += (curr_h - this.loc.height) / 2;*/
      this.connect_object();
    }
  }

  removeChild(ch: ReFabricObject): void {
    this.fjs_object?.removeWithUpdate(ch.fjs_object!);
  }

  is_group: boolean = true;

  protected create_fjs_object(callback: () => void) {
    this.fjs_object = new fabric.Group([], {
      left: this.loc.left,
      top : this.loc.top,
      // width       : this.loc.width,
      // height      : this.loc.height,
      hasControls : this.allow_resizing && !this.lock_movements,
      lockRotation: !this.allow_resizing || this.lock_movements,
      // angle           : this.angle ?? 0,
      centeredRotation: true,
      selectable      : true,
      lockMovementX   : this.lock_movements,
      lockMovementY   : this.lock_movements,
      lockScalingX    : this.lock_movements,
      lockScalingY    : this.lock_movements,
    });

    if (!this.allow_resizing || this.lock_movements)
      this.fjs_object.controls = {
        ...fabric.Group.prototype.controls,
        mtr: new fabric.Control({visible: false})
      };

    this.fjs_object.on('mousedown', this.mousedown);

    (window as any)['fjs_group'] = this.fjs_object;

    // console.log(`${this.constructor.name}.create_fjs_object (${this.debug_name}):`, this.loc.toString(), this.angle);
  }

  private mousedown(e: IEvent) {
    if (e.button == 3) { //right click
      this.$emit('context_menu', this);
    }
  }


}

