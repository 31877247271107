











import {Component, Prop, Vue} from 'vue-property-decorator';
import Logo from "@/components/common/Logo.vue";

@Component<Confirmation>(
    {
      components: {Logo}
    }
)
export default class Confirmation extends Vue {
  @Prop()
  message!: string;
}
