












































import {Component, Prop, Vue} from 'vue-property-decorator';
import Config from "@/classes/common/Config";
import FileRecord from "vue-file-agent/types/src/lib/file-record";

export enum UploaderState {
  no_image = 0,
  tmp_image = 1,
  perm_image = 2
}

@Component(
    {}
)
export default class ReImageUploader extends Vue {
  private config = Config;

  @Prop()
  image_url?: string;

  @Prop({default: false})
  required!: boolean;

  @Prop({default: ''})
  upload_dir!: string;

  private show_validity = false;

  private get valid(): boolean {
    return !(this.required && this.state === UploaderState.no_image);
  }

  UploaderState = UploaderState;

  private bak_image_url?: string;

  private get state(): UploaderState {
    if (!this.image_url) return UploaderState.no_image;
    else if (this.files.length) return UploaderState.tmp_image;
    else return UploaderState.perm_image;
  }

  private files: FileRecord[] = [];

  private image_changed() {
    setTimeout(() =>
                   this.$emit('image_changed', {
                     // url: `${image_tmp_web_path}/${this.files[0].upload.data.dir}/${this.files[0].name()}`,
                     url: this.files[0].upload.data.file_url,
                     w  : this.files[0].dimensions?.width,
                     h  : this.files[0].dimensions?.height
                   }),
               50);
  }

  private async image_remove() {
    const result = await this.$bvModal.msgBoxConfirm(`Delete this image?`)
    if (result) {
      this.$emit('image_remove', this.image_url);
      this.files = [];
    }
  }

  checkValidity() {
    this.show_validity = true;
    return this.valid;
  }

}
