












































































import {Component, Ref, Vue} from 'vue-property-decorator';
import ShoppingCart from "@/components/Reservations/ShoppingCart.vue";
import PayPalButtons from "@/components/Reservations/PayPalButtons.vue";
import ShoppingCartFooter from "@/components/Reservations/ShoppingCartFooter.vue";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import CollapsibleCard from "@/components/common/CollapsibleCard.vue";
import {BForm} from "bootstrap-vue";
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {AppRepo} from "@/classes/repos/AppRepo";

@Component<CheckoutPayment>(
    {
      components: {CollapsibleCard, ShoppingCartFooter, PayPalButtons, ShoppingCart}
    }
)
export default class CheckoutPayment extends Vue {
  private booking_id = '';

  private user_account: { first_name?: string, last_name?: string, email?: string } = {};
  private user_account_created = false;
  private create_account_errors = '';

  private tos = '';
  private tos_accepted = false;

  @Ref()
  create_account_form!: BForm;

  async created() {
    this.tos = await AppRepo.get_setting('tos');
  }

  mounted() {
    this.booking_id = BookingsRepo.current_booking?.id.toString() ?? '';
  }

  private async create_account() {
    this.create_account_errors = '';
    if (!this.create_account_form.checkValidity()) {
      this.create_account_form.reportValidity();
      return;
    }

    await this.$recaptchaLoaded();
    const recaptcha_token = await this.$recaptcha('register_user');

    const {
            success,
            errors
          } = await AuthRepo.register_user(this.user_account.first_name!, this.user_account.last_name!, this.user_account.email!, recaptcha_token);

    this.user_account_created = success;
    this.create_account_errors = errors?.single_err_msg ?? '';
  }
}
