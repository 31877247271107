







import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ReSpinner extends Vue {
  @Prop({default: ''})
  message!: string;

  @Prop({default: 'secondary'})
  variant!: string;
}
