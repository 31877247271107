






















































import {Component, Vue} from 'vue-property-decorator';
import {BoothType} from "@/classes/floor-map/BoothType";
import ReFilter from "@/components/common/ReFilter.vue";
import BoothTypeFullEditor from "@/components/editors/BoothTypeFullEditor.vue";
import App from "@/App.vue";
import BoothTypeImage from "@/components/common/BoothTypeImage.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component(
    {
      components: {BoothTypeImage, BoothTypeFullEditor, ReFilter,},
    }
)
export default class BoothTypesComp extends Vue {
  $refs!: {
    editor: BoothTypeFullEditor
  }

  private filter: string | null = null;

  private BoothTypesGenericRepo = BoothTypesGenericRepo

  private map = MapRepo.current_map;

  async beforeMount() {
    await BoothTypesGenericRepo.load_booth_types();
  }

  private get booth_types_filtered() {
    return BoothTypesGenericRepo.all_booth_types_sorted.filter(x => x.short_desc.toLowerCase().includes(this.filter?.toLowerCase() ?? ''));
  }

  private async toggle_is_favourite(bt: BoothType) {
    bt.is_favourite = !bt.is_favourite;
    await BoothTypesGenericRepo.save(bt);
  }

  private async add() {
    const bt = BoothType.default(ShowsGenericRepo.current_show!.id);
    await this.$refs.editor.open(bt);
    await BoothTypesGenericRepo.load_booth_types();
  }

  private async remove(bt: BoothType, n: number) {
    const bt_usages = BoothTypesGenericRepo.find_usages(bt);
    if (bt_usages) {
      this.$emit('modal', `Cannot remove this booth as it's used in the map.\n Usages:\n ${bt_usages.map(x => `${x.booth.booth_type.letter_index}${x.booth.numeric_index} in layer ${x.map.floor_plan!.name}`).join('\n')}`, 'danger');
      return;
    }

    const confirmed: boolean = await this.$bvModal.msgBoxConfirm(`Delete this booth?`);
    if (!confirmed) return;

    await BoothTypesGenericRepo.remove(bt.id);

    App.notify('Booth removed');
    await BoothTypesGenericRepo.load_booth_types();
  }

  private async delete_unused_bts() {
    const unused_bts = BoothTypesGenericRepo.find_unused();
    if (!unused_bts) {
      App.notify('No unused booths found', 'warning');
      return;
    }

    const confirmed: boolean = await this.$bvModal.msgBoxConfirm(`Delete these booths: ${unused_bts.map(bt => bt.title || `(${bt.rw_sizes()})`).join(', ')}?`);
    if (!confirmed) return;

    await BoothTypesGenericRepo.remove_multiple(unused_bts.map(bt => bt.id));
    App.notify('Booths removed');
    await BoothTypesGenericRepo.load_booth_types();
  }

}
