





































import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import DataProviderContext from "@/classes/common/DataProviderContext";
import {ReHelper} from "@/classes/common/ReHelper";
import {IBoothInfo} from "@/classes/floor-map/IBoothInfo";
import {BTable} from "bootstrap-vue";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import Show from "@/classes/floor-map/Show";
import FloorPlanSelector from "@/components/floor-map/FloorPlanSelector.vue";

@Component<BoothSelector>(
    {
      components: {FloorPlanSelector, ShowSelector}
    }
)
export default class BoothSelector extends Vue {

  @Prop({default: () => []})
  exclude_booths!: string[];

  @Prop({default: null})
  show_initial!: Show | null;

  fp_id: number | null = null;

  @Ref()
  protected table!: BTable;

  fields = [
    {key: 'booth_number', sortable: true,},
    {key: 'price', sortable: true, formatter: 'format_money'},
    {
      key      : 'rw_width',
      label    : 'Size',
      sortable : true,
      formatter: (value: string, key: string, item: IBoothInfo) => `${ReHelper.format_num(item.rw_width)} x ${ReHelper.format_num(item.rw_height)}`
    },
    {
      key      : 'vendor_name',
      label    : 'Occupied By',
      sortable : true,
      formatter: (value: string, key: string, item: IBoothInfo) => item.is_on_hold ? 'On Hold' : value
    },
    {key: 'add', label: '',},
  ];

  protected sort_by = 'booth_number';
  protected sort_desc = false;
  protected busy = false;

  format_money = ReHelper.format_money;

  private show: Show | null = null;

  async created() {
    this.show = this.show_initial ?? null;
  }

  protected async data_provider(ctx: DataProviderContext): Promise<IBoothInfo[]> {
    if (!this.show || !this.fp_id) return [];

    this.busy = true;

    const {
            booths,
            errors,
            success
          } = await ShowsGenericRepo.list_booths_with_booking_info(this.show!.id, this.fp_id, ctx.sortBy, ctx.sortDesc);

    this.busy = false;

    return booths ?? [];
  }

  private floor_plan_changed(show: Show | null, fp_id: number | null) {
    this.show = show;
    this.fp_id = fp_id;
    this.table?.refresh();
  }

}
