





























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  CreateOrderActions,
  OnApproveActions,
  OnApproveData,
  PayPalButtonsComponent,
} from "@paypal/paypal-js/types/components/buttons";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {RawLocation} from "vue-router";
import ReSpinner from "@/components/common/re-spinner.vue";
import App from "@/App.vue";
import {EventBus} from "@/classes/common/EventBus";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<PayPalButtons>(
    {
      components: {ReSpinner}
    }
)
export default class PayPalButtons extends Vue {
  @Prop({default: null})
  return_route_name!: RawLocation | null;

  @Prop({default: () => BookingsRepo.current_booking?.id})
  booking_id!: number;

  @Prop({default: () => BookingsRepo.current_booking?.total})
  max_amount!: number;

  @Prop({default: () => BookingsRepo.current_booking?.min_pay_amount})
  min_amount!: number;

  private buttons: PayPalButtonsComponent | undefined;

  // @Model()
  private amount_to_pay: number = 0;

  private payment_mode: 'Pay in full' | 'Pay partial' = 'Pay in full';

  private rendered = false;

  private use_store_credit = false;

  JwtRepo = JwtRepo;

  private get amount_ok() {
    return !this.amount_too_low && !this.amount_too_high;
  }

  private get amount_too_low() {
    return this.amount_to_pay < this.min_amount;
  }

  private get amount_too_high() {
    return this.amount_to_pay > this.max_amount;
  }

  get paying_by_store_credit_only() {
    return this.use_store_credit && JwtRepo.full_store_credit >= this.amount_to_pay;
  }

  private async createOrder(data: Record<string, unknown>, actions: CreateOrderActions): Promise<string> {
    const {
      success,
      paypal_order_id,
      errors
    } = await BookingsRepo.create_paypal_order(this.amount_to_pay, this.booking_id, this.use_store_credit);

    if (errors)
      App.notify(errors.single_err_msg, 'danger');

    return paypal_order_id ?? '';
  }

  private async onApprove(data: OnApproveData, actions: OnApproveActions): Promise<void> {
    const {errors, success} = await BookingsRepo.approve_paypal_order(data.orderID);

    if (errors)
      App.notify(errors.single_err_msg, 'danger');
    else if (this.return_route_name)
      await this.$router.push(this.return_route_name);

    EventBus.$emit('paypal_payment_completed', this.booking_id);
  }

  private async mounted() {
    this.amount_to_pay = this.max_amount;

    // this.paypal = await loadScript({"client-id": BookingsRepo.paypal_client_id})!;

    if (BookingsRepo.paypal) {
      try {
        if (this.buttons && this.buttons.close && this.rendered) {
          await this.buttons.close();
          this.rendered = false;
        }

        this.buttons = BookingsRepo.paypal.Buttons?.({
          createOrder: this.createOrder,
          onApprove: this.onApprove,
          onError: this.error,
          onShippingChange: async (data, actions) => actions.resolve(),
        });
        await this.buttons?.render("#paypal-buttons");

        this.rendered = true;

      } catch (error) {
        let selector = document.querySelector('#paypal-buttons');
        if (selector && selector.children.length > 0) {
          // still mounted so throw an error
          console.error("failed to render the PayPal Buttons", error);
        }
      }
    }
  }

  private async beforeDestroy() {
    if (this.buttons && this.buttons.close && this.rendered) {
      await this.buttons.close();
      this.rendered = false;
    }
  }

  private error(error: Record<string, unknown>) {
  }

  @Watch('max_amount')
  private max_amount_changed() {
    this.amount_to_pay = this.max_amount;
  }

  async pay_via_store_credit() {
    const {errors, success} = await BookingsRepo.pay_via_store_credit(this.booking_id, this.amount_to_pay);

    if (!errors)
      await this.$router.push({name: 'thank_you', params: {booking_id: String(this.booking_id)}});
    else
      App.notify(errors.single_err_msg, 'danger');
  }
}
