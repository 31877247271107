























import {Component, Vue} from 'vue-property-decorator';
import Sellable_Services_Table from "@/components/generic/Sellable_Services_Table.vue";
import ReFilter from "@/components/common/ReFilter.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<Sellable_Services>(
    {
      components: {ReFilter, Sellable_Services_Table}
    }
)
export default class Sellable_Services extends Vue {
  private filter: string | null = null;

  JwtRepo = JwtRepo
}
