




























































































import {Component, Prop, Vue} from "vue-property-decorator";
import TitlePanel from "../floor-map/TitlePanel.vue";
import {ReHelper} from "@/classes/common/ReHelper";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faIdCard} from '@fortawesome/free-regular-svg-icons'
import App from "@/App.vue";
import {AppRepo} from "@/classes/repos/AppRepo";
import AdminMenu from "@/components/common/AdminMenu.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import VendorSidebar from "@/components/Reservations/VendorSidebar.vue";
import CheckoutMenu from "@/components/common/CheckoutMenu.vue";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import AccountMenu from "@/components/common/AccountMenu.vue";
import {MapRepo} from "@/classes/repos/MapRepo";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";

library.add(faIdCard);

@Component({
  components: {AccountMenu, CheckoutMenu, VendorSidebar, AdminMenu, TitlePanel}
})
export default class ReMenuSidebar extends Vue {
  private AppRepo = AppRepo;

  private get show() {
    return ShowsGenericRepo.current_show!;
  }

  private get is_map_pages_group() {
    return this.$route.meta?.group_name === 'map pages';
  }

  private get discounts_valid() {
    return !this.show?.multi_table_discounts?.some(d => !d.is_valid);
  }

  @Prop({default: true})
  left_sidebar_opened!: boolean;

  private ReHelper = ReHelper;

  private async save_show() {
    await BoothTypesGenericRepo.save_all_changed();
    await BoothTypesGenericRepo.load_booth_types();
    BoothTypesGenericRepo.remap();

    let errors: ErrorForDisplay | undefined, success: boolean;
    ({
      errors,
      success
    } = await ShowsGenericRepo.save_multi_table_discounts(this.show.id, this.show.multi_table_discounts));

    if (errors) {
      App.notify(errors.single_err_msg, 'danger');
      return;
    }

    ({errors, success} = await ShowsGenericRepo.save(this.show));

    await ShowsGenericRepo.save_editing_history(this.show.id, MapRepo.undo_buffer);

    if (!errors)
      App.notify('Show saved');
    else
      App.notify(errors.single_err_msg, 'danger');
  }
}
