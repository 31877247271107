


































































import {Component, Vue} from 'vue-property-decorator';
import {fabric} from "fabric";
import {base_URL} from "@/classes/common/Const";

import vSelect from 'vue-select'
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import Loc from "@/classes/common/Loc";
import BoothComp from "@/components/floor-map/BoothComp.vue";
import ComplexBoothComp from "@/components/floor-map/ComplexBoothComp.vue";
import {MapRepo} from "@/classes/repos/MapRepo";

Vue.component('v-select', vSelect)

//{key:'image_url', formatter: (value, key, item)=>item.type=='image'? item.getSrc():'' }

@Component<DebugComp>(
    {
      components: {}
    }
)
export default class DebugComp extends Vue {
  Loc = Loc;

  MapRepo = MapRepo;

  fields = [
    {key: 'w.debug_name', formatter: this.r},
    'f.type',
    'f.angle',
    'fjs_loc',
    'wrapper_loc',
    {key: 'w.angle', formatter: this.num_format},
    {key: 'w', label: 'booth_loc', formatter: this.booth_loc},
    {key: 'booth_uid', label: 'Booth UID'},
    'content',
    {key: 'booth_type_size', label: 'Booth Type Size'},
    {key: 'booth_type_uid', label: 'Booth Type UID'},
    // 'wrapper',
  ];

  private type_filter: string[] = ['group'];

  fjs_objects?: fabric.Object[] = [];
  wrapper_objects: ReFabricObject[] = [];
  combined_objects: Array<{ f: fabric.Object, w?: ReFabricObject }> = [];

  private load_objects() {
    // this.fjs_objects = this.wrapper_objects = this.combined_objects = [];
    this.fjs_objects?.splice(0);
    this.wrapper_objects.splice(0);
    this.combined_objects.splice(0);
    this.fjs_objects = MapRepo.active_canvas?.fjs_canvas?._objects?.filter(o => o.type! != 'line')
                              .flatMap(o => o.type === 'group' ? [o, ...(o as fabric.Group)._objects.flatMap(o1 => o1.type === 'group' ? [o1, ...(o1 as fabric.Group)._objects] : o1)] : o);

    this.wrapper_objects = ReFabricObject.re_fabric_objects.filter(o => !!o && this.fjs_objects?.includes(o.fjs_object!));

    this.combined_objects = this.fjs_objects?.map(o => ({
      f              : o,
      w              : this.wrapper_objects.find(x => x.fjs_object === o),
      booth_uid      : '',
      booth_type_size: '',
      booth_type_uid : '',
    })) ?? [];

  }

  private async remount() {
    this.$emit('remount');
    await this.$nextTick();
    setTimeout(() => this.load_objects(), 100);
  }

  get objects_filtered() {
    return this.type_filter.length ?
        this.combined_objects!.filter(o => this.type_filter.includes(o.f.type!))
        : this.combined_objects;
  }

  fjs_objects_filtered = [];

  /*get fjs_objects_filtered(): fabric.Object[] {
    return this.type_filter.length ?
        this.fjs_objects!.filter(o => this.type_filter.includes(o.type!))
        : this.fjs_objects!.filter(o => o.type! != 'line');
  }*/

  w_fjs_objects(): fabric.Object[] | undefined {
    // return (window as any).fjs_objects;
    return MapRepo.active_canvas?.fjs_canvas?._objects.flatMap(o => o.type === 'group' ? [o, ...(o as fabric.Group)._objects] : o);
  }

  base_URL = base_URL;

  t = '';

  num_format(x: number): string {
    return x?.toFixed(0);
  }

  r(s: string): string {
    return this.t + s;
  }

  booth_loc(o: any) {
    // if (!(o instanceof ReFabricGroup)) return '';
    if (o.$parent instanceof BoothComp) return o.$parent.booth.loc;
    if (o.$parent instanceof ComplexBoothComp) return o.$parent.booth.loc;
    return '';
  }

  booth_type_size(o: any) {
    if (o.$parent instanceof BoothComp || o.$parent instanceof ComplexBoothComp) return o.$parent.booth.booth_type.rw_sizes();
    return '';
  }

  refresh() {
    this.load_objects();
    this.t = '';
    // this.$forceUpdate();
  }

  async mounted() {
    await this.$nextTick();
    this.refresh();
  }

}
