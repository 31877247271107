





































import {Component, Ref, Vue} from 'vue-property-decorator';
import {MapRepo} from "@/classes/repos/MapRepo";
import BookingItem from "@/classes/bookings/BookingItem";
import TreeAccordion from "@/components/common/TreeAccordion.vue";
import {ReHelper} from "@/classes/common/ReHelper";
import {EventBus} from "@/classes/common/EventBus";

export type tree_node = { name: string, child_keys: { [id: string]: any }, children?: tree_nodes, grand_children?: tree_nodes; opened?: boolean };
export type tree_nodes = { [id: string]: tree_node };
export type tree_level = { get_children?: ((x: tree_node) => (tree_nodes | undefined)), sort_children?: ((a: tree_node, b: tree_node) => number) };

@Component<VendorSidebar>(
    {
      components: {TreeAccordion},
    }
)
export default class VendorSidebar extends Vue {
  /*categories = new Map<string, { name: string, children: Set<number> }>();
  vendors = new Map<number, { name: string, children: Set<string> }>();
  booked_booths = new Map<string, Booth>();*/
  private MapRepo = MapRepo;

  private entities_to_show: string[] = [];
  private entities_options = [
    {text: 'Categories', value: 'category'},
    {text: 'Exhibitors', value: 'vendor'},
    {text: 'Booths', value: 'booth'},
  ];

  private filter = '';

  @Ref('tree_accordion')
  private tree_accordion!: TreeAccordion;

  private get entities_to_show_sorted() {
    return ['category', 'vendor', 'booth',].filter(e => this.entities_to_show.includes(e));
  }

  private get top_level_entity() {
    return this.entities_to_show.includes('category') ? 'category' : this.entities_to_show.includes('vendor') ? 'vendor' : 'booth';
  }

  private any_booth_booked = false;

  private get tree_levels(): tree_level[] {
    switch (this.entities_to_show_sorted.join(',')) {
      case 'category,vendor,booth':
        return [{}, {}, {sort_children: (a, b) => ReHelper.sort_booths(a.name, b.name)}];
      case 'category,vendor':
        return [{}, {}];
      case 'category':
        return [{}];
      case 'vendor,booth':
        return [{}, {sort_children: (a, b) => ReHelper.sort_booths(a.name, b.name)}];
      case 'vendor':
        return [{}];
      case 'category,booth':
        return [{get_children: c => c.grand_children}, {sort_children: (a, b) => ReHelper.sort_booths(a.name, b.name)}];
      case 'booth':
        return [{sort_children: (a, b) => ReHelper.sort_booths(a.name, b.name)}];
    }
    return []
  }

  // all_booths_map = MapRepo.current_map?.all_booths_map();

  categories: tree_nodes = {};
  /*{
    '1': {
      name      : 'Test Category 1',
      child_keys: {'1': '1', '2': '2'}
    },
    '2': {
      name      : 'Test Category 2',
      child_keys: {'1': '1'}
    },
  };*/
  vendors: tree_nodes = {};
  /*{
    '1': {name: 'Test Vendor 1', child_keys: {'A1': 'A1'}},
    '2': {name: 'Test Vendor 2', child_keys: {'A2': 'A2'}},
  };*/
  booked_booths: tree_nodes = {};
  /*{
    'A1': {name: 'Booth A1', child_keys: {}},
    'A2': {name: 'Booth A2', child_keys: {}},
  };*/

  private all_entities = {category: this.categories, vendor: this.vendors, booth: this.booked_booths};

  private async build_bookings_tree(data: { success: boolean; booking_items: BookingItem[]; errors?: any }) {
    // console.log('load_data');

    //save currently opened nodes
    const active_category_name = Object.values(this.categories).find(n => n.opened)?.name;
    const active_vendor_name = Object.values(this.vendors).find(n => n.opened)?.name;

    //cleanup
    Object.keys(this.categories).forEach(k => Vue.delete(this.categories, k));
    Object.keys(this.vendors).forEach(k => Vue.delete(this.vendors, k));
    Object.keys(this.booked_booths).forEach(k => Vue.delete(this.booked_booths, k));
    this.any_booth_booked = false;

    //build data tree structure
    for (const item of data.booking_items) {
      this.any_booth_booked = true;
      const vendor_id = item.vendor_id!.toString();

      //categories
      if (item.vendor_categories)
        for (const vendor_category of [...item.vendor_categories ?? [], ...item.sharing_vendor_categories ?? []]) {
          const id = ReHelper.title_to_id(vendor_category);
          this.categories[id] ??= {name: vendor_category, child_keys: {}};
          this.categories[id].child_keys[vendor_id] ??= vendor_id;
        }

      if (item.sharing_vendor_categories && item.sharing_with_vendor_id)
        for (const vendor_category of item.sharing_vendor_categories) {
          const id = ReHelper.title_to_id(vendor_category);
          this.categories[id] ??= {name: vendor_category, child_keys: {}};
          this.categories[id].child_keys[item.sharing_with_vendor_id] ??= item.sharing_with_vendor_id;
        }

      //vendors
      if (item.vendor_name) {
        this.vendors[vendor_id] ??= {name: item.vendor_name, child_keys: {}};
        this.vendors[vendor_id].child_keys[item.booth_number] ??= item.booth_number;
      }

      //shared vendors
      if (item.sharing_with_vendor_id && item.sharing_with_vendor_name) {
        this.vendors[item.sharing_with_vendor_id] ??= {name: item.sharing_with_vendor_name, child_keys: {}};
        this.vendors[item.sharing_with_vendor_id].child_keys[item.booth_number] ??= item.booth_number;
      }

      //booths
      this.booked_booths[item.booth_number] ??= {name: item.booth_number, child_keys: {}};

      await this.$nextTick();
      this.tree_accordion?.$forceUpdate();
    }

    //link data tree levels
    Object.values(this.categories).forEach(c => c.children = Object.fromEntries(Object.keys(c.child_keys).map(k => [k, this.vendors[k]])));
    Object.values(this.vendors).forEach(v => v.children = Object.fromEntries(Object.keys(v.child_keys).map(k => [k, this.booked_booths[k]])));
    Object.values(this.categories).forEach(c => c.grand_children = Object.fromEntries(
        Object.values(c.children ?? {}).flatMap(v => Object.entries(v?.children ?? {}))
    ));

    // restore previously opened nodes
    if (active_category_name) {
      const active_category = Object.values(this.categories).find(c => c.name == active_category_name);
      if (active_category) active_category.opened = true;
    }

    if (active_vendor_name) {
      const active_vendor = Object.values(this.vendors).find(v => v.name == active_vendor_name);
      if (active_vendor) active_vendor.opened = true;
    }

    await this.$nextTick();
    this.tree_accordion?.$forceUpdate();
  }

  async mounted() {
    EventBus.$on('loaded_bookings_for_map', this.build_bookings_tree);
    this.entities_to_show = ['vendor', 'booth'];
  }

  private node_click(node_key: string, node: tree_node, level: number) {
    let highlighted_booths: { [k: string]: string } = {};
    switch (this.entities_to_show_sorted[level]) {
      case 'category':
        Object.keys(node.grand_children ?? {}).forEach(k => highlighted_booths[k] = k);
        break;
      case 'vendor':
        highlighted_booths = node.child_keys;
        break;
      case 'booth':
        highlighted_booths[node.name] = node.name;
        break;
    }

    MapRepo.highlighted_booths = highlighted_booths;
  }
}
