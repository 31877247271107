






















































import {Component} from 'vue-property-decorator';
import EntityEditor from "@/components/generic/EntityEditor.vue";
import {BForm} from "bootstrap-vue";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";
import {SellableServicesRepo} from "@/classes/repos/SellableServicesRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<SellableServiceEditor>(
    {
      components: {ShowSelector}
    }
)
export default class SellableServiceEditor extends EntityEditor {
  repo = SellableServicesRepo;
  TEntity = Sellable_Service;

  protected entity: (Sellable_Service | null) = null;

  protected setup_entity() {
    this.entity!.copyTo(<Sellable_Service>this.orig_entity);
  }

  protected async load_additional(): Promise<void> {
    if (!this.entity!.id && ShowsGenericRepo.current_show) this.entity!.service_mappings = [ShowsGenericRepo.current_show];
  }
}
