
















































import {Component, Vue} from 'vue-property-decorator';
import LayerSwitch from "@/components/floor-map/LayerSwitch.vue";
import {MapRepo} from "@/classes/repos/MapRepo";
import {ReHelper} from '@/classes/common/ReHelper';
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import {JwtRepo} from "@/classes/repos/JwtRepo";

@Component<MapControls>(
    {
      components: {LayerSwitch, VueSlider}
    }
)
export default class MapControls extends Vue {
  private MapRepo = MapRepo;
  private ReHelper = ReHelper;

  private get show() {
    return ShowsGenericRepo.current_show;
  }

  private get map() {
    return MapRepo.current_map!;
  }

  private get show_slider() {
    return !ReHelper.touch;
  }

  get public_zoom(): number {
    return MapRepo.public_zoom;
  }

  set public_zoom(value: number) {
    if (0.5 <= value && value <= MapRepo.max_public_zoom)
      MapRepo.public_zoom = value;
  }

  private get is_admin() {
    return JwtRepo.is_admin;
  }

  fit_canvas() {
    MapRepo.active_canvas?.fit_canvas();
  }

}
