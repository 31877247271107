




















import {Component, Ref, Vue} from 'vue-property-decorator';
import VendorProfileComp from "@/components/vendor/VendorProfileComp.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";

@Component<CheckoutVendorProfile>(
    {
      components: {VendorProfileComp}
    }
)
export default class CheckoutVendorProfile extends Vue {
  @Ref() profile_editor!: VendorProfileComp;

  async beforeMount() {
    await CompaniesRepo.load_vendor_company();
    CompaniesRepo.current_company?.init_contacts();
  }
}
