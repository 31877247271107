


























































import {Component, Prop, Vue} from 'vue-property-decorator';
import MultiTableDiscount from "@/classes/floor-map/MultiTableDiscount";
import {MapRepo} from "@/classes/repos/MapRepo";
import {uniq} from "lodash-es";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";

@Component<MultiTableDiscountComp>(
    {}
)
export default class MultiTableDiscountComp extends Vue {
  @Prop()
  discount!: MultiTableDiscount;

  private get id(): string {
    return `mtd-${this.discount.id}`;
  }

  MapRepo = MapRepo;
  multi_table_discount_min_booth_count = BookingsRepo.multi_table_discount_min_booth_count;

  get available_booth_types() {
    return uniq(BoothTypesGenericRepo.all_booth_types.map(t => t.letter_index)).sort()
        .filter(t => this.discount.booth_type == t || !ShowsGenericRepo.current_show!.multi_table_discounts?.some(d => d.booth_type == t));
  }

  async delete_group() {
    if (!(await this.$bvModal.msgBoxConfirm(`Remove this booth discount group?`))) return;

    this.$emit('delete_discount', this.discount);
  }
}
