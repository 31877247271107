












import {Component, Prop, Vue} from 'vue-property-decorator';
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";

@Component<ValidationSummary>(
    {}
)
export default class ValidationSummary extends Vue {
  @Prop()
  errors: ErrorForDisplay | null = null;

  /*private get errors_flat() {
    return Object.keys(this.errors).flatMap((k: string) => this.errors[k]);
  }*/

  private get have_errors(): boolean {
    return !!this.errors && Object.keys(this.errors).length > 0;
  }
}
