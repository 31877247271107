











































































































import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import {BForm} from "bootstrap-vue";
import AddressEditor from "@/components/common/AddressEditor.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import App from "@/App.vue";
import CollapsibleCard from "@/components/common/CollapsibleCard.vue";
import CompanyContactEditor from "@/components/editors/CompanyContactEditor.vue";
import ReSpinner from "@/components/common/re-spinner.vue";
import Company from "@/classes/companies/Company";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import ValidationSummary from "@/components/common/ValidationSummary.vue";
import {ReHelper} from "@/classes/common/ReHelper";
import {mask} from 'vue-the-mask'
import UserSelector from "@/components/generic/UserSelector.vue";
import {UsersRepo} from "@/classes/repos/UsersRepo";


@Component<VendorProfileComp>(
    {
      components: {
        UserSelector,
        ValidationSummary,
        ReSpinner, CompanyContactEditor, CollapsibleCard, AddressEditor,
      },
      directives: {mask}
    }
)
export default class VendorProfileComp extends Vue {
  @Ref()
  form!: BForm;

  /*  @Prop({default: () => AuthRepo.vendor_id})
    vendor_id!: number;*/

  @Prop()
  company!: Company;

  @Prop({default: 'Save Profile'})
  save_btn_text!: string;

  @Prop()
  after_save_action?: Function;

  private errors: ErrorForDisplay | null | undefined = null;

  private get owner() {
    return this.company?.contacts?.find(c => c.type === 'Owner') ?? null;
  }

  private get alt_contact() {
    return this.company?.contacts?.find(c => c.type === 'Alt Contact') ?? null;
  }

  private async mounted() {
    // await this.load();
  }

  private async submit() {
    this.errors = null;

    if (!this.form.checkValidity()) {
      this.form.reportValidity();
      return;
    }

    const clone = this.company!.clone();

    //bypass saving Alt Contact if it's empty
    clone.contacts = [this.owner!];
    if (!this.alt_contact!.is_empty())
      clone.contacts.push(this.alt_contact!);

    const {success, errors, id} = await CompaniesRepo.save(clone);

    if (success) {
      await UsersRepo.get_connected_companies();
      if (this.after_save_action)
        this.after_save_action(id);
      else this.default_after_save_action();
    } else {
      this.errors = errors;
      ReHelper.scroll_to_top();
    }
  }

  default_after_save_action() {
    App.notify('The Exhibitor Profile saved successfully')
  }

}
