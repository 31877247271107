
















import {Component, Prop, Vue} from 'vue-property-decorator';
import FloorPlanSelector from "@/components/floor-map/FloorPlanSelector.vue";
import Show from "@/classes/floor-map/Show";
import SellableServiceSelector from "@/components/generic/SellableServiceSelector.vue";
import Sellable_Service from "@/classes/floor-map/Sellable_Service";

@Component<SellableServiceBookingItemBuilder>(
    {
      components: {SellableServiceSelector, FloorPlanSelector}
    }
)
export default class SellableServiceBookingItemBuilder extends Vue {
  show: Show | null = null;
  fp_id: number | null = null;

  @Prop({default: []})
  exclude_services!: number[];

  @Prop({default: null})
  show_initial!: Show | null;

  created() {
    this.show = this.show_initial;
  }

  private floor_plan_changed(show: Show | null, fp_id: number | null) {
    this.show = show;
    this.fp_id = fp_id;
  }

  private async selected_services_changed(service: Sellable_Service) {
    this.$emit('add_service', service, this.show, this.fp_id);
  }

}
