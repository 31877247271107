











import {Component, Vue} from 'vue-property-decorator';
import {VueEditor} from "vue2-editor";
import {AppRepo} from "@/classes/repos/AppRepo";
import App from "@/App.vue";

@Component<SettingsTermsOfService>(
    {
      components: {VueEditor}
    }
)
export default class SettingsTermsOfService extends Vue {
  tos = '';

  async created() {
    this.tos = await AppRepo.get_setting('tos');
  }

  async save() {
    await AppRepo.save_setting('tos', this.tos);
    App.notify('Settings Saved')
  }
}
