















































import {Component, Vue} from 'vue-property-decorator';
import ReFilter from "@/components/common/ReFilter.vue";
import CompaniesTable from "@/components/generic/CompaniesTable.vue";
import UsersTable from "@/components/generic/UsersTable.vue";
import {AuthRepo} from "@/classes/repos/AuthRepo";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import Show from "@/classes/floor-map/Show";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import CompanySelector from "@/components/generic/CompanySelector.vue";
import Company from "@/classes/companies/Company";

@Component<UsersGenericComp>(
    {
      components: {CompanySelector, ShowSelector, UsersTable, CompaniesTable, ReFilter}
    }
)
export default class UsersGenericComp extends Vue {
  private filter: string | null = null;

  private roles = [{id: '', name: 'Any'}, ...AuthRepo.roles];

  private shows: Show[] = [];
  private companies: Company[] = [];

  private filter_request: { roles?: string[], booked_shows_ids: number[], company_ids: number[] } = {
    roles           : [''],
    booked_shows_ids: [],
    company_ids     : []
  };

  created() {
    if (ShowsGenericRepo.current_show) {
      this.shows = [ShowsGenericRepo.current_show];
      this.filter_request.booked_shows_ids = this.shows.map(sh => sh.id);
    }
  }

  shows_selection_changed() {
    this.filter_request.booked_shows_ids = this.shows.map(sh => sh.id);
  }

  companies_selection_changed() {
    this.filter_request.company_ids = this.companies.map(c => c.id);
  }

}
