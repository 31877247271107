








import {Component, Vue} from 'vue-property-decorator';
import MapEditor from "@/components/floor-map/MapEditor.vue";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component<LayoutPage>(
    {
      components: {MapEditor},
    }
)
export default class LayoutPage extends Vue {

  private created() {
    MapRepo.editor_mode = 'layout';
  }

  /*  private async mounted() {
      let self = this;
      (this as any).$eh.$on('force_new_floorplan', () => self.force_new_floorplan = true);
      (this as any).$eh.$on('force_edit_floorplan', () => self.force_edit_floorplan = true);
    }

    private get edit_fp() {
      return this.force_edit_floorplan || this.force_new_floorplan || !this.map;
    }*/


}
