




import {Component, Vue} from 'vue-property-decorator';
import {RawLocation, Route} from "vue-router";

@Component(
    {
      beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: AccountPagesGroup) => void))) => void) {
        next(async vm => {
        })
      },
    }
)
export default class AccountPagesGroup extends Vue {

}
