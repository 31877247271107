











import {Component, Prop, Vue} from 'vue-property-decorator';
import User from "@/classes/users/User";

@Component<VendorMappings>(
    {}
)
export default class VendorMappings extends Vue {
  @Prop()
  data!: { item: User };
}
