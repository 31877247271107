import {LikeableEntityGenericRepo} from "@/classes/repos/LikeableEntityGenericRepo";
import Vue from "vue";
import User from "@/classes/users/User";
import axios from "axios";
import {merge} from "lodash";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import Company from "@/classes/companies/Company";
import {JwtRepo} from "@/classes/repos/JwtRepo";

export type t_user_settings = {
    editor_buttons?: {
        redraw_canvas?: boolean,
        toggle_panning?: boolean,
        toggle_grid?: boolean,
        toggle_floorplan_image_editing?: boolean,
        add_booths?: boolean,
        booth_size?: boolean,
        remove?: boolean,
        lock?: boolean,
        group?: boolean,
        align?: boolean,
        magnetic_corner?: boolean,
        copy?: boolean,
        place_object?: boolean,
        undo?: boolean,
        change_booth_number?: boolean,
        get_png: true,
    }
};

export const default_user_settings: t_user_settings = {
    editor_buttons: {
        redraw_canvas: true,
        toggle_panning: true,
        toggle_grid: true,
        toggle_floorplan_image_editing: true,
        add_booths: true,
        booth_size: true,
        remove: true,
        lock: true,
        group: true,
        align: true,
        magnetic_corner: true,
        copy: true,
        place_object: true,
        undo: true,
        change_booth_number: true,
        get_png: true,
    }
};

class _UsersRepo extends LikeableEntityGenericRepo<{ created_on?: string, has_bookings?: boolean, roles?: string[], limit_by_ids?: number[], exclude_ids?: number[] }> {
    get user_settings(): t_user_settings | null {
        return this._user_settings;
    }

    set user_settings(value: t_user_settings | null) {
        this._user_settings = merge(default_user_settings, value ?? {});
        // this._user_settings = {...default_user_settings, ...value ?? {}};
    }

    private _user_settings: t_user_settings | null = default_user_settings;

    constructor() {
        super(User, 'User');
        /*EventBus.on('login', (user_settings: t_user_settings) => this.user_settings = user_settings);
        EventBus.on('user_settings', () => this.user_settings = default_user_settings);*/
    }

    /*get effective_user_settings(): t_user_settings {
        return {...default_user_settings, ...this.user_settings ?? {}};
    }*/

    async load_user_settings() {
        const {data: {settings}} = await axios.post('/App/user_settings');
        this.user_settings = settings;
    }

    async change_user_setting(category: string, name: string, val: string) {
        await axios.post('/App/change_user_setting', {}, {params: {category, name, val}});
    }

    async resend_activation_email(user_id: number): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/User/resend_activation_email', {}, {params: {user_id}});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async get_connected_companies(): Promise<{ success: boolean; companies?: Company[], user_data?: { first_name: string; last_name: string }; errors?: ErrorForDisplay }> {
        try {
            const {
                data: {
                    companies,
                    user_data,
                    company_credit_info
                }
            } = await axios.get('/Account/get_connected_companies');
            JwtRepo.companies = companies ?? [];
            if (company_credit_info) {
                JwtRepo.store_credit = company_credit_info.store_credit;
                JwtRepo.total_overpayments = company_credit_info.total_overpayments;
            }
            return {success: true, companies, user_data};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async switch_company(company_id: number): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            const {
                data: {
                    jwt,
                    company_credit_info
                }
            } = await axios.post('/Account/switch_company', {}, {params: {company_id}});
            JwtRepo.jwt = jwt;
            if (company_credit_info) {
                JwtRepo.store_credit = company_credit_info.store_credit;
                JwtRepo.total_overpayments = company_credit_info.total_overpayments;
            }
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async disconnect_company(company_id: number): Promise<{ success: boolean; companies?: Company[]; errors?: ErrorForDisplay }> {
        try {
            const {data: {companies}} = await axios.post('/Account/disconnect_company', {}, {params: {company_id}});
            JwtRepo.companies = companies;
            return {success: true, companies};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async delete_company(company_id: number): Promise<{ success: boolean; companies?: Company[]; errors?: ErrorForDisplay }> {
        try {
            const {data: {companies}} = await axios.post('/Account/delete_company', {}, {params: {company_id}});
            JwtRepo.companies = companies;
            return {success: true, companies};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async get_user_data(): Promise<{ success: boolean; user_data?: { first_name: string; last_name: string }; errors?: ErrorForDisplay }> {
        try {
            const {data: {user_data}} = await axios.get('/Account/get_user_data');
            return {success: true, user_data};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }
}

export const UsersRepo = Vue.observable(new _UsersRepo);