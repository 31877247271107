










































import {Component, Vue} from 'vue-property-decorator';
import {UsersRepo} from "@/classes/repos/UsersRepo";
import Company from "@/classes/companies/Company";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import App from "@/App.vue";

@Component<CompaniesComp>(
    {
      components: {}
    }
)
export default class CompaniesComp extends Vue {
  get vendor_id(): number {
    return JwtRepo.vendor_id;
  }

  set vendor_id(company_id: number) {
    UsersRepo.switch_company(company_id);
  }

  JwtRepo = JwtRepo;

  async created() {
    const {companies, errors} = await UsersRepo.get_connected_companies();
    if (companies) JwtRepo.companies = companies;
  }

  private async disconnect(company_id: number) {
    if (!(await this.$bvModal.msgBoxConfirm(`Disconnect this company?`))) return;

    JwtRepo.companies = (await UsersRepo.disconnect_company(company_id)).companies!;
  }

  private async remove(company: Company) {
    if (!(await this.$bvModal.msgBoxConfirm(`Delete "${company.name}" company? This operation cannot be undone.`))) return;

    const {errors, companies} = (await UsersRepo.delete_company(company.id));

    if (errors)
      App.notify(errors.single_err_msg, 'danger');
    else {
      App.notify(`Company "${company.name}" deleted`);
      JwtRepo.companies = companies!;
    }

  }
}
