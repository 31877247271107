























import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import CompanySelector from "@/components/generic/CompanySelector.vue";
import BookingItem from "@/classes/bookings/BookingItem";
import Company from "@/classes/companies/Company";
import {BModal} from "bootstrap-vue";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";

@Component<SharedVendorSelector>(
    {
      components: {CompanySelector}
    }
)
export default class SharedVendorSelector extends Vue {
  @Prop()
  booking_vendor_id!: number;

  shared_vendor: Company | null = null;

  @Ref()
  modal_share_booth!: BModal;

  private booking_item?: BookingItem;

  open(booking_item: BookingItem) {
    this.booking_item = booking_item;
    this.shared_vendor = booking_item.sharing_with_vendor_id ? <Company>{
      id: booking_item.sharing_with_vendor_id,
      name: booking_item.sharing_with_vendor_name
    } : null;
    this.modal_share_booth.show();
  }

  async save() {
    await BookingsRepo.set_shared_vendor(this.booking_item!.id, this.shared_vendor?.id ?? null);

    this.booking_item!.sharing_with_vendor_id = this.shared_vendor?.id ?? null;
    this.booking_item!.sharing_with_vendor_name = this.shared_vendor?.name ?? null;
  }
}
