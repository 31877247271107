








import {Component, Vue} from 'vue-property-decorator';
import TopPanel from "@/components/common/TopPanel.vue";

@Component<NotFoundComponent>(
    {
      components: {TopPanel}
    }
)
export default class NotFoundComponent extends Vue {

}
