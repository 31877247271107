






































import {Component, Prop, Vue} from 'vue-property-decorator';
import CollapsibleCard from "@/components/common/CollapsibleCard.vue";
import AddressEditor from "@/components/common/AddressEditor.vue";
import CompanyContact from "@/classes/companies/CompanyContact";
import {mask} from 'vue-the-mask'

@Component<CompanyContactEditor>(
    {
      components: {AddressEditor, CollapsibleCard},
      directives: {mask}
    }
)
export default class CompanyContactEditor extends Vue {
  @Prop()
  contact!: CompanyContact;

  @Prop({default: false})
  contact_required!: boolean;

  @Prop({default: true})
  opened!: boolean;

}
