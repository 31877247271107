












































import {Component, Prop, Vue} from 'vue-property-decorator';
import PayPalButtons from "@/components/Reservations/PayPalButtons.vue";
import {JwtRepo} from '@/classes/repos/JwtRepo';


@Component<ShoppingCartFooter>(
    {
      components: {PayPalButtons}
    }
)
export default class ShoppingCartFooter extends Vue {

  @Prop({default: true})
  show_buttons!: boolean;

  JwtRepo = JwtRepo;

}
