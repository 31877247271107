

























































import {Component, Watch} from 'vue-property-decorator';
import EntityEditor from "@/components/generic/EntityEditor.vue";
import {BForm} from "bootstrap-vue";
import {WaitlistRepo} from "@/classes/repos/WaitlistRepo";
import WaitlistItem from "@/classes/floor-map/WaitlistItem";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import CompanySelector from "@/components/generic/CompanySelector.vue";
import Company from "@/classes/companies/Company";
import Show from "@/classes/floor-map/Show";
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import ShowSelectorForVendorWaitlist from "@/components/generic/ShowSelectorForVendorWaitlist.vue";

@Component<WaitlistItemEditor>(
    {
      components: {ShowSelectorForVendorWaitlist, CompanySelector}
    }
)
export default class WaitlistItemEditor extends EntityEditor {
  repo = WaitlistRepo;
  TEntity = WaitlistItem;

  selected_company: Company | null = null;

  protected get entity_single_name(): string {
    return 'Waitlist Request';
  }

  private get is_admin() {
    return JwtRepo.is_admin;
  }

  private selected_show: Show | null = null;

  protected entity: (WaitlistItem | null) = null;

  private ShowsGenericRepo = ShowsGenericRepo;

  protected async load_additional(): Promise<void> {
    this.selected_company = this.entity!.vendor_id ? {
      id: this.entity!.vendor_id,
      name: this.entity!.vendor_name
    } as Company : null;
  }

  mounted() {
    if (ShowsGenericRepo.current_show) this.selected_show = ShowsGenericRepo.current_show;
  }

  protected setup_entity() {
    this.entity!.show_id = this.selected_show!.id;
    this.entity!.copyTo(<WaitlistItem>this.orig_entity);
  }

  protected check_validity_additional(): boolean {
    this.validity_check_requested = true;
    return !!this.entity!.vendor_id && !!this.selected_show;
  }

  @Watch('selected_company')
  private selected_company_changed() {
    this.entity!.vendor_id = this.selected_company?.id ?? null;
  }

  @Watch('ShowsGenericRepo.current_show')
  private show_changed() {
    this.selected_show = ShowsGenericRepo.current_show;
  }

}
