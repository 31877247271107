

























import {Component, Vue} from 'vue-property-decorator';
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import App from "@/App.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import Register from "@/components/pages/Account/Register.vue";

@Component<CompanyUsers>(
    {
      components: {Register}
    }
)
export default class CompanyUsers extends Vue {
  users: { first_name: string; last_name: string; email: string }[] = [];

  JwtRepo = JwtRepo;

  async created() {
    await this.load_users();
  }

  private async load_users() {
    this.users = await CompaniesRepo.get_company_users();
  }

  async remove_user(email: string) {
    if (!(await this.$bvModal.msgBoxConfirm(`Remove user ${email}?`))) return;

    const {errors, success} = await CompaniesRepo.remove_user(email);
    if (errors) App.notify(errors.single_err_msg, 'danger');
    else {
      App.notify('User removed');
      await this.load_users();
    }
  }

  async add_user() {

  }
}
