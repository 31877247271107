












import {Component, Prop, Vue} from 'vue-property-decorator';
import ShoppingCart from "@/components/Reservations/ShoppingCart.vue";
import ShoppingCartFooter from "@/components/Reservations/ShoppingCartFooter.vue";

@Component<CheckoutThankYou>(
    {
      components: {ShoppingCartFooter, ShoppingCart}
    }
)
export default class CheckoutThankYou extends Vue {
  @Prop()
  booking_id!: number;
}
