









import {Component, Prop, Ref, Vue} from 'vue-property-decorator';
import VendorProfileComp from "@/components/vendor/VendorProfileComp.vue";
import {CompaniesRepo} from "@/classes/repos/CompaniesRepo";
import Company from "@/classes/companies/Company";
import App from "@/App.vue";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import User from "@/classes/users/User";

@Component<VendorProfile>(
    {
      components: {VendorProfileComp}
    }
)
export default class VendorProfile extends Vue {
  @Ref() profile_editor!: VendorProfileComp;

  @Prop({default: 0})
  company_id!: number;

  private company: Company | null = null;

  async beforeMount() {
    const company_id = this.company_id;

    if (!company_id) { // new company
      this.company = Company.default();
      this.company.users = [new User(JwtRepo.user_id, JwtRepo.email!, "", true, [], JwtRepo.first_name!, JwtRepo.last_name!, "", "", undefined, undefined)];
    } else {
      const {entity, errors} = await CompaniesRepo.details(company_id);
      if (entity)
        this.company = entity as Company;
      else
        App.notify(errors!.single_err_msg, 'danger');
    }

    this.company?.init_contacts();
  }

  private async after_save(company_id: number) {
    App.notify('Company saved successfully')
    if (this.$route.name === 'new_vendor_profile') {
      await this.$router.replace({name: 'vendor_profile', params: {company_id: String(company_id)}});
    }
  }

}
