




































import {Component, Prop, Vue} from 'vue-property-decorator';
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import App from "@/App.vue";
import Booking from "@/classes/bookings/Booking";

export type manual_payment = { id: number, amount: number, notes?: string };

@Component<ManualPaymentEditor>(
    {}
)
export default class ManualPaymentEditor extends Vue {
  @Prop()
  booking!: Booking;

  private payment: manual_payment = {id: 0, amount: 0};

  private editor_visible = false;

  private orig_amount = 0;

  open(payment?: manual_payment) {
    this.orig_amount = payment?.amount ?? 0;
    this.payment = payment ? {...payment} : {id: 0, amount: 0};
    this.editor_visible = true;
  }

  get is_new() {
    return this.payment.id === 0;
  }

  private get payment_valid() {
    return true;// this.payment.amount > 0;// && this.payment.amount - this.orig_amount <= (this.booking!.balance_due ?? 0);
  }

  private async add_or_update_manual_payment() {
    const {
      errors,
      booking
    } = await BookingsRepo.add_or_update_manual_payment(this.booking!.id, this.payment.id, this.payment.amount, this.payment.notes);

    if (booking) {
      this.$emit('update_booking', booking);
      // this.$forceUpdate()
    } else App.notify(errors!.single_err_msg, 'danger');
  }
}
