





































import {Component, Prop, Vue} from 'vue-property-decorator';
import Booking from "@/classes/bookings/Booking";

@Component<BookingRowDetails>(
    {}
)
export default class BookingRowDetails extends Vue {
  @Prop()
  row!: { item: Booking, index: number };

  get booking() {
    return this.row.item;
  }
}
