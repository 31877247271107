

















































import {Component, Prop, Vue} from 'vue-property-decorator';
import CollapsibleCard from "@/components/common/CollapsibleCard.vue";
import {usStates} from "@/js/states";
import {mask} from 'vue-the-mask';

export type PhysicalAddress = { line1?: string, line2?: string, line3?: string, city?: string, zip?: string, state?: string, country?: string };

@Component<AddressEditor>(
    {
      components: {CollapsibleCard},
      directives: {mask}
    }
)
export default class AddressEditor extends Vue {
  @Prop()
  address!: PhysicalAddress;

  @Prop({default: 'Address'})
  title!: string;

  @Prop({default: 'sm'})
  input_size!: string;

  @Prop({default: true})
  address_required!: boolean;

  private usStates = usStates;
}
