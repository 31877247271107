


















import {Component, Vue} from 'vue-property-decorator';
import {AuthRepo} from "@/classes/repos/AuthRepo";
import {RawLocation, Route} from "vue-router";
import LoginForm from "@/components/common/LoginForm.vue";
import EntitySelector from "@/components/generic/EntitySelector.vue";
import ShowSelector from "@/components/generic/ShowSelector.vue";
import EntityTable from "@/components/generic/EntityTable.vue";

@Component<LoginPage>(
    {
      components: {EntityTable, ShowSelector, EntitySelector, LoginForm},
      beforeRouteEnter(to: Route, from: Route, next: (to?: (RawLocation | false | ((vm: LoginPage) => void))) => void) {
        next(async vm => {
          // if (AuthRepo.is_logged_in) await vm.$router.push(AuthRepo.get_default_location());
          // document.body.classList.add('login-page');
        })
      },
    }
)
export default class LoginPage extends Vue {
  private async success() {
    await this.$router.push(AuthRepo.get_default_location());
  }

}
