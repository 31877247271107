import Vue from "vue";
import axios from "axios";
import {RawLocation} from "vue-router";
import User from "@/classes/users/User";
import router from "@/router";
import App from "@/App.vue";
import {AppRepo} from "@/classes/repos/AppRepo";
import ErrorForDisplay from "@/classes/common/ErrorForDisplay";
import {JwtRepo} from "@/classes/repos/JwtRepo";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {EventBus} from "@/classes/common/EventBus";
import {default_user_settings, UsersRepo} from "@/classes/repos/UsersRepo";

async function refreshAuthLogic(failedRequest: any) {
    // console.log('refreshAuthLogic called');
    JwtRepo.clear_jwt();
    const login_completed = await App.instance.login_popup.open();
    // failedRequest.config.headers['Authorization'] = 'Bearer ' + AuthRepo.jwt;
    return login_completed ? Promise.resolve() : Promise.reject();
}

class _AuthRepo {
    roles: { id: string, name: string }[] = [];

    init() {
        createAuthRefreshInterceptor(axios, refreshAuthLogic);

        axios.interceptors.request.use(config => {
            // Do something before request is sent
            config.headers!.authorization = JwtRepo.auth_header.Authorization;
            if (AppRepo.ui_level === "vendor" && JwtRepo.is_admin)
                config.headers!.admin_vendor_mode = true;
            return config;
        }, error => {
            // Do something with request error
            return error;
            // return Promise.reject(error);
        });

        JwtRepo.read_stored_jwt();

        AppRepo.ui_level = JwtRepo.is_admin ? "admin" : "vendor";

    }

    async login(email: string, pwd: string, recaptcha_token: string): Promise<{ success: boolean; reason?: string; }> {
        const a = axios.create();
        try {
            const {
                data: {
                    success,
                    jwt,
                    companies,
                    first_name,
                    last_name,
                    user_settings,
                    company_credit_info,
                }
            } = await a.post(`/Login/`, {
                email,
                pwd,
                recaptcha_token
            });
            if (success) {
                JwtRepo.jwt = jwt;
                EventBus.$emit('login', user_settings);
                UsersRepo.user_settings = user_settings;
                JwtRepo.first_name = first_name;
                JwtRepo.last_name = last_name;
                JwtRepo.companies = companies;

                if (company_credit_info) {
                    JwtRepo.store_credit = company_credit_info.store_credit;
                    JwtRepo.total_overpayments = company_credit_info.total_overpayments;
                }

                return {success: true};
            } else {
                JwtRepo.jwt = null;
                return {success: false, reason: 'Invalid email or password'};
            }
        } catch (ex) {
            console.log(ex);
            return {success: false, reason: <string>ex.response?.data?.detail};
        }
    }

    async sign_out() {
        JwtRepo.clear_jwt();
        AppRepo.ui_level = "vendor";
        EventBus.$emit('sign_out');
        UsersRepo.user_settings = default_user_settings;
        await router.push({path: '/'});
    }

    get_default_location(): RawLocation {
        return {name: 'shows'};
    }

    async check_token(token_type: string, token: string): Promise<{ token_ok: boolean; last_name: string; first_name: string; email: string }> {
        const {
            data: {
                token_ok,
                first_name,
                last_name,
                email
            }
        } = await axios.post(`/Account/check_token`, null, {params: {token, token_type}});
        return {token_ok, first_name, last_name, email};
    }

    async activate_account(token_type: string, oneTimeToken: string, pwd: string, recaptcha_token: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            const {
                data: {
                    jwt,
                    first_name,
                    last_name,
                    companies,
                    user_settings,
                    company_credit_info
                }
            } = await axios.post(`/Account/activate_account`, {
                oneTimeToken,
                pwd,
                recaptcha_token
            }, {params: {token_type}});

            JwtRepo.jwt = jwt;
            JwtRepo.first_name = first_name;
            JwtRepo.last_name = last_name;
            JwtRepo.companies = companies;
            UsersRepo.user_settings = user_settings;

            if (company_credit_info) {
                JwtRepo.store_credit = company_credit_info.store_credit;
                JwtRepo.total_overpayments = company_credit_info.total_overpayments;
            }

            return {success: true};
        } catch (ex) {
            console.log(ex);
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async load_frontend_user_data(): Promise<{ phone: string; last_name: string; first_name: string; email: string }> {
        const {data: {first_name, last_name, email, phone}} = await axios.post('/account/load_frontend_user_data');
        return {first_name, last_name, email, phone};
    }

    async save_frontend_user_data(user: User): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/account/save_frontend_user_data', user);
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async change_pwd(pwd: string): Promise<{ success: boolean; errors?: any }> {
        try {
            await axios.post('/account/change_pwd', {pwd});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async request_password_reset(email: string, recaptcha_token: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/account/request_password_reset', {email, recaptcha_token});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async reset_password(pwd: string, recaptcha_token: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/account/reset_password', {pwd, recaptcha_token});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }

    async register_user(first_name: string, last_name: string, email: string, recaptcha_token: string): Promise<{ success: boolean; errors?: ErrorForDisplay }> {
        try {
            await axios.post('/account/register_user', {email, first_name, last_name, recaptcha_token});
            return {success: true};
        } catch (ex) {
            return {success: false, errors: new ErrorForDisplay(ex)};
        }
    }


}

export const AuthRepo = Vue.observable(new _AuthRepo());
