























import {Component, Prop, Vue} from 'vue-property-decorator';
import Show from "@/classes/floor-map/Show";

@Component
export default class TitlePanel extends Vue {
  @Prop()
  show?: Show
}

