import XDate from "xdate";

export default class Payment {
    id: number;
    date: string;
    amount: number;
    gateway: string;
    tran_number: string;
    is_refunded: boolean;
    notes: string;

    constructor(id: number, date: string, amount: number, gateway: string, tran_number: string, is_refunded: boolean, notes: string) {
        this.id = id;
        this.date = date;
        this.amount = amount;
        this.gateway = gateway;
        this.tran_number = tran_number;
        this.is_refunded = is_refunded;
        this.notes = notes;
    }

    static from(o: any) {
        return new Payment(
            o.id,
            o.date,
            o.amount,
            o.gateway,
            o.tran_number,
            o.is_refunded,
            o.notes,
        )
    }

    get date_formatted() {
        return new XDate(this.date).toString('M/d/yyyy')
    }
}