import Loc from "@/classes/common/Loc";
import {BoothType, ComplexBoothType} from "@/classes/floor-map/BoothType";
import {pix_per_foot} from "@/classes/common/Const";
import IBoothQuickProps from "@/classes/floor-map/IBoothQuickProps";
import {BoothTypesGenericRepo} from "@/classes/repos/BoothTypesGenericRepo";
import {Exclude} from "class-transformer";
import {MapRepo} from "@/classes/repos/MapRepo";

export enum MapObjectTypes {
    MapObject            = 'MapObject',
    PrintOnlyObject      = 'PrintOnlyObject',
    Booth                = 'Booth',
    ComplexBooth         = 'ComplexBooth',
    ComplexBoothTypePart = 'ComplexBoothTypePart',
}

export class MapObject {

    loc: Loc;

    angle: number = 0;

    locked: boolean = false;

    type = MapObjectTypes.MapObject;

    private static UID_base = 0;

    @Exclude()
    readonly UID: number;

    constructor(loc: Loc, angle: number = 0, locked: boolean = false) {
        this.loc = loc;
        this.angle = angle;
        this.locked = locked;
        this.UID = ++MapObject.UID_base;
    }

    static from(o: any): MapObject | undefined {
        try {
            switch (o.type) {
                case MapObjectTypes.Booth:
                    return Booth.from(o);
                case MapObjectTypes.ComplexBooth:
                    return ComplexBooth.from(o);
                case MapObjectTypes.PrintOnlyObject:
                    return PrintOnlyObject.from(o);
            }
        } catch (e) {
            console.log(e)
        }
    }

    clone(): MapObject {
        throw new Error('Cloning abstract class');
    }

    update_loc(loc: Loc, angle?: number) {
        this.loc = loc;
        if (angle !== undefined)
            this.angle = angle;
    }

    get booth(): Booth | undefined {
        return this.type === MapObjectTypes.Booth ? this as any as Booth : undefined;
    }

    get booth_or_complex_booth(): Booth | undefined {
        return this.type === MapObjectTypes.Booth || this.type === MapObjectTypes.ComplexBooth ? this as any as Booth : undefined;
    }

    get print_only_object(): PrintOnlyObject | undefined {
        return this.type === MapObjectTypes.PrintOnlyObject ? this as any as PrintOnlyObject : undefined;
    }

    get complex_booth(): ComplexBooth | undefined {
        return this.type === MapObjectTypes.ComplexBooth ? this as any as ComplexBooth : undefined;
    }

    /*    is_booth(): this is Booth {
            return this.type === 'Booth' || this.type === 'ComplexBooth';
        }*/

}

export class PrintOnlyObject extends MapObject {
    type = MapObjectTypes.PrintOnlyObject;
    image_url: string;

    constructor(image_url: string, loc: Loc, angle: number = 0, locked: boolean = false) {
        super(loc, angle, locked);
        this.image_url = image_url;
    }

    static from(o: any): PrintOnlyObject {
        return new PrintOnlyObject(
            o.image_url,
            Loc.from(o.loc),
            o.angle,
            o.locked,
        );
    }

    clone(): PrintOnlyObject {
        return new PrintOnlyObject(this.image_url, this.loc.clone(), this.angle, this.locked);
    }
}

export default class Booth extends MapObject implements IBoothQuickProps {
    type = MapObjectTypes.Booth;

    title: string = '';

    get booth_type(): BoothType {
        const boothType = this.get_booth_type();
        /*if (!boothType)
            debugger;*/
        return boothType;
    }

    set booth_type(bt: BoothType) {
        if (bt.is_public) {
            this.booth_type_id = bt.id;
            this.internal_booth_type = undefined;
        } else {
            this.booth_type_id = undefined;
            this.internal_booth_type = bt;//.clone();
        }
    }

    internal_booth_type?: BoothType;

    booth_type_id?: number;

    get is_booth_type_public() {
        return this.booth_type_id !== undefined;
    }

    protected get_booth_type(): BoothType {
        return this.booth_type_id ? BoothTypesGenericRepo.get_by_id(this.booth_type_id)! : this.internal_booth_type!;
    }

    numeric_index: number;

    get booth_number() {
        return this.booth_type.letter_index + this.numeric_index;
    }

    price_override: number | null = null;

    days_override: number | null = null;

    is_on_hold = false;

    get price(): number | null {
        return this.price_override ?? this.booth_type?.price;
    }

    set price(v) {
        if (!v && v !== 0) v = null; //fix for empty string

        if (v == this.booth_type?.price) {
            this.price_override = null;
        } else {
            this.price_override = v;
        }
    }

    get price_overridden(): boolean {
        return this.price_override !== null;
    }

    get days(): number | null {
        return this.days_override ?? this.booth_type?.days;
    }

    set days(v) {
        if (v == this.booth_type?.days) {
            this.days_override = null;
        } else {
            this.days_override = v;
        }
    }

    get has_overrides(): boolean {
        return this.price_override !== null || this.days_override !== null;
    }

    reset_price() {
        this.price_override = null;
    }

    constructor(title: string,
                loc: Loc,
                booth_type_id?: number,
                internal_booth_type?: BoothType,
                numeric_index: number         = 0,
                angle: number                 = 0,
                price_override: number | null = null,
                days_override: number | null  = null,
                locked: boolean               = false,
                is_on_hold: boolean           = false,
    ) {
        super(loc, angle, locked);
        this.title = title;
        this.booth_type_id = booth_type_id;
        this.internal_booth_type = booth_type_id ? undefined : internal_booth_type;
        this.price_override = price_override;
        this.numeric_index = numeric_index;
        this.days_override = days_override;
        this.is_on_hold = is_on_hold;

        this.update_size_from_booth_type();
    }

    update_size_from_booth_type() {
        this.loc.width = this.booth_type?.rw_width * pix_per_foot;
        this.loc.height = this.booth_type?.rw_height * pix_per_foot;
    }

    make_booth_type_internal() {
        if (this.booth_type_id) {
            let booth_type = this.get_booth_type().clone();
            this.booth_type_id = undefined;
            booth_type.id = 0;
            this.internal_booth_type = booth_type;
        }
    }

    static from(o: any): Booth {
        return new Booth(
            o.title,
            Loc.from(o.loc),
            o.booth_type_id,
            o.internal_booth_type ? BoothType.from(o.internal_booth_type) : undefined,
            o.numeric_index,
            o.angle,
            o.price_override,
            o.days_override,
            o.locked,
            o.is_on_hold,
        )
    }

    static from_loc_and_type(loc: Loc, booth_type: BoothType): Booth {
        if (booth_type instanceof ComplexBoothType)
            return new ComplexBooth('', loc, booth_type);

        if (booth_type.is_public)
            return new Booth('', loc, booth_type.id);
        else return new Booth('', loc, undefined, booth_type);
    }

    clone(): Booth {
        return new Booth(this.title, this.loc.clone(), this.booth_type_id, this.internal_booth_type, 0, this.angle, this.price_override, this.days_override, this.locked, this.is_on_hold);
    }

    toComplexBoothTypePart(group_center: Loc, fill?: string) {
        return new ComplexBoothTypePart(this.loc.subtract(group_center), this.angle, this.booth_type_id, this.internal_booth_type, undefined,
                                        this.price_override, this.days_override, fill);
    }

}

export class ComplexBooth extends Booth {
    type = MapObjectTypes.ComplexBooth;

    get booth_type(): ComplexBoothType {
        return this.get_booth_type() as ComplexBoothType;
    }

    constructor(title: string, loc: Loc, booth_type: ComplexBoothType, numeric_index: number = 0, angle: number = 0, price_override: number | null = null, days_override: number | null = null, locked: boolean = false, is_on_hold: boolean = false) {
        super(title, loc, booth_type.id, booth_type, numeric_index, angle, price_override, days_override, locked, is_on_hold);
    }

    static from(o: any): ComplexBooth {
        const complexBooth = new ComplexBooth(o.title,
                                              Loc.from(o.loc),
                                              ComplexBoothType.from(o.booth_type_id || o.internal_booth_type),
                                              o.numeric_index,
                                              o.angle,
                                              o.price_override,
                                              o.days_override,
                                              o.locked,
                                              o.is_on_hold,
        );
        return complexBooth;
    }

    clone(): ComplexBooth {
        return new ComplexBooth(this.title, this.loc.clone(), this.booth_type, this.numeric_index, this.angle, this.price_override, this.days_override, this.locked, this.is_on_hold);
    }
}

export class ComplexBoothTypePart extends Booth {
    type = MapObjectTypes.Booth;

    constructor(loc: Loc, angle: number, booth_type_id?: number, internal_booth_type?: BoothType, imageURL?: string,
                price_override: number | null = null, days_override: number | null = null, fill?: string) {
        if (booth_type_id)
            internal_booth_type = BoothTypesGenericRepo.get_by_id(booth_type_id)!.clone();
        internal_booth_type!.fill = fill ?? MapRepo.booth_fill_color;
        super('', loc, undefined, internal_booth_type, 0, angle, price_override, days_override, false);
    }

    static from(o: any): ComplexBoothTypePart {
        return new ComplexBoothTypePart(
            Loc.from(o.loc),
            o.angle,
            o.booth_type_id,
            o.internal_booth_type ? BoothType.from(o.internal_booth_type) : undefined,
            o.price_override,
            o.days_override,
        )
    }
}