







































































import {MapRepo} from '@/classes/repos/MapRepo';
import {Component, Vue} from 'vue-property-decorator';
import {ShowsGenericRepo} from "@/classes/repos/ShowsGenericRepo";
import VendorProfileMenu from "@/components/common/VendorProfileMenu.vue";

@Component<AdminMenu>(
    {
      components: {VendorProfileMenu}
    }
)
export default class AdminMenu extends Vue {
  private MapRepo = MapRepo;

  private get show() {
    return ShowsGenericRepo.current_show!;
  }

  private get slug() {
    return ShowsGenericRepo.current_show?.slug ?? '';
  }

  private get fp_slug() {
    return MapRepo.current_map?.floor_plan?.slug;
  }

  private group_opened(group_name: string): boolean {
    return this.$route.matched.some(r => r.name == group_name || r.meta.group_name == group_name);
    // return this.$route.fullPath.startsWith('/' + group_name);
  }
}
