
import {Component, Prop} from 'vue-property-decorator';
import EntityTable, {field_definition} from "@/components/generic/EntityTable.vue";
import {BookingsRepo} from "@/classes/repos/BookingsRepo";
import Booking from "@/classes/bookings/Booking";
import {RawLocation} from "vue-router";
import {LikeableEntity} from "@/classes/repos/LikeableEntityGenericRepo";
import {ReHelper} from "@/classes/common/ReHelper";
import CompanyLink from "@/components/vendor/CompanyLink.vue";
import BookingRowDetails from "@/components/Reservations/BookingRowDetails.vue";

@Component<BookingsTable>(
    {
      components: {CompanyLink, BookingRowDetails}
    }
)
export default class BookingsTable extends EntityTable {
  @Prop({default: false})
  admin_mode!: boolean;

  @Prop({
    default: () => {
    }
  })
  filter_request: any;

  protected get default_fields(): (field_definition | undefined)[] {
    return [
      {key: 'id', label: 'Order Id', sortable: true,},
      {key: 'created_on', label: 'Booking Date', sortable: true, formatter: 'format_date'},
      this.admin_mode ? {key: 'vendor_name', label: 'Exhibitor', sortable: true, component: 'CompanyLink'} : undefined,
      this.admin_mode ? undefined : {
        key: 'show',
        sortable: false,
        formatter: (value: string, key: string, item: Booking) => item.show ? item.show.name : ''
      },
      // this.admin_mode ? {key: 'placed_by', sortable: true,} : undefined,
      // {key: 'booth_cnt', label: 'Booths', sortable: true,},
      this.admin_mode ? {key: 'vendor_email', label: "Exhibitor's Email", sortable: true,} : undefined,
      // this.admin_mode ? undefined : {key: 'shows', label: 'Show', sortable: false, component: 'ShowBriefInfo'},
      {key: 'status', label: 'Booking Status', sortable: true, formatter: 'format_booking_status'},
      {key: 'total', label: 'Booking Total', sortable: true, formatter: 'format_money'},
      {key: 'payments_total', label: 'Payments Total', sortable: true, formatter: 'format_money'},
      {key: 'custom_discount', label: 'Custom Discount', sortable: true, formatter: 'format_money'},
      {key: 'discount_total', label: 'Discount Total', sortable: true, formatter: 'format_money'},
      {key: 'balance_due', label: 'Balance Due', sortable: true, formatter: 'format_money'},
      {key: this.admin_mode ? 'view_backend_order' : 'view_my_account_order', label: 'Action', sortable: false,},
    ];
  }

  repo = BookingsRepo;
  TEntity = Booking;

  actions: { key: string; text: string; to: (b: LikeableEntity) => RawLocation }[] = [{
    key: 'view_my_account_order',
    text: 'View',
    to: b => ({
      name: 'booking',
      params: {booking_id: b.id.toString()}
    })
  }, {
    key: 'view_backend_order',
    text: 'View',
    to: b => ({
      name: 'order',
      params: {booking_id: b.id.toString()}
    })
  },];

  sort_by = 'created_on';
  sort_desc = true;

  row_details_comp = 'BookingRowDetails';

  private format_booking_status(status: number): string {
    const booking_status = BookingsRepo.booking_statuses[status];
    if (booking_status == 'OverPaid') return 'OverPaid';
    return ReHelper.camelcase_to_sentence(booking_status);
  }

  get search_request(): any {
    return this.filter_request;
  }

}
