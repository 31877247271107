




import {Component, Prop, Watch} from 'vue-property-decorator';
import {fabric} from "fabric";
import ICanvasChild from "@/components/interfaces/ICanvasChild";
import ReFabricObject from "@/components/re-fabric/re-fabric-object";
import {MapRepo} from "@/classes/repos/MapRepo";

@Component
export default class ReFabricText extends ReFabricObject implements ICanvasChild {
  fjs_object?: fabric.Text;

  @Prop()
  text!: string;

  @Prop({default: false})
  linethrough!: boolean;

  protected create_fjs_object(callback: () => void) {
    this.fjs_object = new fabric.Text(this.text || ' ', {
      left       : this.loc.left,
      top        : this.loc.top,
      fontSize   : 16 / (Math.pow(MapRepo.internal_zoom, 0.7)),
      originX    : 'center',
      originY    : 'center',
      angle      : this.angle ?? 0,
      linethrough: this.linethrough,
      /*lockUniScaling: true,
      lockScalingX  : true,
      lockScalingY  : true,*/
    });
    this.fjs_object?.set({angle: this.angle ?? 0});
    // console.log(`${this.constructor.name}.create_fjs_object (${this.debug_name}):`, this.angle, this.fjs_object?.angle);

    setTimeout(() => {
      if (this.lowest_layer) {
        this.fjs_object?.sendToBack();
      }
      if (this.highest_layer) {
        this.fjs_object?.bringToFront();
      }
    }, 200);


    (window as any)['fjs_text'] = this.fjs_object;
    callback();
  }

  protected listen_fjs_events() {
  }


  @Watch('text')
  modified_text_from_model(text: string) {
    this.fjs_object?.set({text});
  }

  @Watch('angle')
  modified_angle_from_model(angle: number) {
    this.fjs_object?.rotate(angle);
    this.fjs_object?.straighten();
    // console.log(`${this.constructor.name}.modified_angle_from_model:`, angle, this.fjs_object?.angle);
    /*setTimeout(() =>
                   console.log(`${this.constructor.name}.modified_angle_from_model after timeout:`, angle, this.fjs_object?.angle),
               200
    );*/
  }

  @Watch('linethrough')
  private linethrough_changed() {
    this.fjs_object?.set({linethrough: this.linethrough});

  }

}

